import React, { useState, useEffect, Fragment } from 'react'
import LayoutModal from "../../components/LayoutModal"
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon, CurrencyDollarIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid'
import { getListRevenda, deletarRevenda } from '../../services/revenda.js'
import { useNavigate, useLocation } from "react-router-dom";
import LayoutPaginacaoTabela from "../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { getListMunicipiosPorUf, getListUf } from '../../services/endereco'
import useDebounce from '../../hooks/useDebounce';
import API_URL from '../../utils/api'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function RevendaListagem() {
    let navigate = useNavigate();
    const location = useLocation();

    //acessos
    const desc_funcionalidade = "REVENDA";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')
    const [cadastrar, setCadastrar] = useState('')
    const [editar, setEditar] = useState('')
    const [excluir, setExcluir] = useState('')

    const [listaRevenda, setListaRevenda] = useState([])
    const [confirmacaoDeletar, setConfirmacaoDeletar] = useState(false)
    const [revendaSelecionada, setRevendaSelecionada] = useState(null)

    const [loading, setLoading] = useState(true)

    //Filtros
    const [filtroUf, setFiltroUf] = useState({ value: null, label: "Todas" })
    const [opcoesUf, setOpcoesUf] = useState([]);
    const ufDebounce = useDebounce(carregaOpcoesUf, 500)
    const [pesquisaUf, setPesquisaUf] = useState("")

    const [filtroMunicipio, setFiltroMunicipio] = useState({ value: null, label: "Todas" })
    const [opcoesMunicipio, setOpcoesMunicipio] = useState([]);
    const municipioDebounce = useDebounce(carregaOpcoesMunicipio, 500)
    const [pesquisaMunicipio, setPesquisaMunicipio] = useState("")

    const [inicioPeriodo, setInicioPeriodo] = useState(null);
    const [fimPeriodo, setFimPeriodo] = useState(null);
    const [filtroPesquisa, setFiltroPesquisa] = useState(null);

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroUf, filtroMunicipio, inicioPeriodo, fimPeriodo, filtroPesquisa])

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = 10

    function handleNovaRevenda() {
        if (!cadastrar) {
            toast.error(`Sem acesso a Cadastro de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        navigate(`/revenda/form`)
    }

    function handleVisualizarRevenda(id) {
        if (!editar) {
            toast.error(`Sem acesso a Visualização de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        navigate(`/revenda/form`, { state: { "idRevenda": id } })
    }

    function handleDeleteRevenda(dados) {
        if (!excluir) {
            toast.error(`Sem acesso a Exclusão de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        setRevendaSelecionada(dados)
        setConfirmacaoDeletar(true)
    }

    async function confirmDeleteRevenda() {
        await deletarRevenda(revendaSelecionada.id)
        carregaRevendas(registrosPorPagina, paginaAtual)

        toast.success('Revenda deletada com sucesso.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    async function carregaOpcoesUf(pesquisa = pesquisaUf) {
        const response = await getListUf(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(uf => {
                return {
                    value: uf.id,
                    label: uf.descricao
                }
            })
            setOpcoesUf([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaOpcoesMunicipio(pesquisa = pesquisaMunicipio, ufId = filtroUf.value) {
        setFiltroMunicipio({ value: null, label: "Selecione" })

        if (!ufId) {
            setOpcoesMunicipio([])
            return
        }

        const response = await getListMunicipiosPorUf(pesquisa, ufId)
        if (response.sucesso) {
            const opcoes = response.data.map(municipio => {
                return {
                    value: municipio.id,
                    label: municipio.descricao
                }
            })
            setOpcoesMunicipio([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const LinhaTabela = (props) => {
        const {
            id,
            arquivoLogoId,
            nomeFantasia,
            municipio,
            ativo,
            documento,
            tipoPessoa,
            dataValidade
        } = props.dadosLinha

        const documentoMask = (d) => {
            if (tipoPessoa === "PF" || d.length <= 11) {
                return d.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            } else if (tipoPessoa === "PJ" || d.length > 11) {
                return d.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/, '$1.$2.$3/$4-$5');
            }
        }

        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-2 sm:px-6">
                    <div className="grid lg:grid-cols-4 grid-cols-1 gap-4 items-center">

                        <div className='flex flex-row flex-wrap items-center'>
                            <img
                                className="h-12 w-12 mr-4 rounded-lg object-contain"
                                src={arquivoLogoId ? `${API_URL}/arquivo/download/${arquivoLogoId}` : require('../../assets/images/user.png')}
                                alt=""
                            />
                            <div>
                                <div className="text-sm font-medium text-gray-800 flex truncate capitalize">
                                    <p className='truncate mr-1'>{id} - {nomeFantasia}</p>
                                </div>
                                <div className="text-sm text-gray-500 flex truncate">
                                    <p className='truncate mr-1'>Documento: {documentoMask(documento)}</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="text-sm font-medium text-gray-800 truncate">
                                Cidade: {municipio}
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="text-sm font-medium text-gray-800 truncate">
                                Validade: {new Date(dataValidade).toLocaleDateString()}
                            </div>
                        </div>

                        <div className="self-center justify-between flex">
                            <div data-for={`${id}`} data-tip className="flex items-center lg:justify-center text-sm text-white font-semibold">
                                {ativo ?
                                    <p className='rounded-full bg-green-600 px-2'>
                                        Ativo
                                    </p>
                                    :
                                    <p className='rounded-full bg-red-600 px-2'>
                                        Inativo
                                    </p>
                                }
                            </div>
                            {(editar || excluir) && <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                        <span className="sr-only">Open options</span>
                                        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-30 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {editar && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={e => handleVisualizarRevenda(id)}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        <PencilIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        <span>Editar Revenda</span>
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                            {excluir && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm'
                                                        )}
                                                        onClick={() => handleDeleteRevenda(props.dadosLinha)}
                                                    >
                                                        <TrashIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        <span>Deletar</span>
                                                    </div>
                                                )}
                                            </Menu.Item>}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>}
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso(location.pathname.split("/")[1],
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
                { acao: setCadastrar, nome: desc_funcionalidade + "_CADASTRAR" },
                { acao: setEditar, nome: desc_funcionalidade + "_EDITAR" },
                { acao: setExcluir, nome: desc_funcionalidade + "_EXCLUIR" }
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaRevendas(registrosPorPagina, paginaAtual)
                carregaOpcoesUf()
                carregaOpcoesMunicipio()
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaRevendas = async (pageSize = registrosPorPagina, currentPage = 0) => {
        const filtros = {
            pageSize,
            currentPage,
            ufId: filtroUf.value,
            municipioId: filtroMunicipio.value,
            pesquisa: filtroPesquisa,
            fimPeriodo: fimPeriodo,
            inicioPeriodo: inicioPeriodo,
        }

        const response = await getListRevenda(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.currentPage)
            setTotalRegistros(response.data.totalRegisters)
            setTotalPaginas(response.data.totalPages)

            setListaRevenda(response.data.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const filtroDebounce = useDebounce(carregaRevendas, 500)

    const renderLinhasTabela = () => {
        return listaRevenda.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    function handleSelectUf(ufSelecionadda) {
        carregaOpcoesMunicipio('', ufSelecionadda.value || null)
        setFiltroUf(ufSelecionadda)
    }

    function handleSelectMunicipio(municipioSelecionado) {
        setFiltroMunicipio(municipioSelecionado)
    }

    useEffect(() => {
        if (pesquisaUf) ufDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaUf])

    useEffect(() => {
        if (pesquisaMunicipio) municipioDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaMunicipio])

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <>
                {!listaRevenda ?
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <EmptyPage texto="Nenhuma Revenda Cadastrado" Icone={CurrencyDollarIcon} botao={true} acao={handleNovaRevenda} />
                        </div>
                    </div>
                    :
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8'>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700 flex items-center">
                                    Pesquisa
                                    <i className='text-xs text-gray-400 ml-4'>
                                        ( Id,
                                        Nome Completo,
                                        Razão Social,
                                        Nome Fantasia,
                                        Documento )
                                    </i>
                                </label>
                                <input
                                    defaultValue={filtroPesquisa}
                                    onChange={e => setFiltroPesquisa(e.target.value)}
                                    type="text"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Pesquisa " />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Uf
                                </label>
                                <Select
                                    isDisabled={loading}
                                    name="uf"
                                    value={filtroUf}
                                    onChange={handleSelectUf}
                                    onInputChange={value => setPesquisaUf(value)}
                                    options={opcoesUf}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma UF disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Municipio
                                </label>
                                <Select
                                    isDisabled={loading}
                                    name="municipio"
                                    value={filtroMunicipio}
                                    onChange={handleSelectMunicipio}
                                    onInputChange={value => setPesquisaMunicipio(value)}
                                    options={opcoesMunicipio}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhum Município disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Início Validade
                                </label>
                                <input
                                    defaultValue={inicioPeriodo}
                                    onChange={e => setInicioPeriodo(e.target.value)}
                                    type="date"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Fim Validade
                                </label>
                                <input
                                    defaultValue={fimPeriodo}
                                    onChange={e => setFimPeriodo(e.target.value)}
                                    type="date"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                            </div>
                        </div>
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-2 flex items-center justify-between sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Revenda
                                        </h3>
                                    </div>
                                    <div className="ml-4 mt-2 flex-shrink-0">
                                        {cadastrar && <button
                                            onClick={handleNovaRevenda}
                                            type="button"
                                            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                            Adicionar
                                        </button>}
                                    </div>
                                </div>
                            </div>

                            <ul className="divide-y divide-gray-200">
                                {renderLinhasTabela()}
                            </ul>

                            <LayoutPaginacaoTabela
                                pagina={paginaAtual}
                                totalRegistros={totalRegistros}
                                registrosPorPagina={registrosPorPagina}
                                totalPaginas={totalPaginas}
                                onClickPaginaAnterior={() => {
                                    setPaginaAtual(paginaAtual - 1)
                                    carregaRevendas(registrosPorPagina, paginaAtual - 1)
                                }}
                                onClickPaginaPosterior={() => {
                                    setPaginaAtual(paginaAtual + 1)
                                    carregaRevendas(registrosPorPagina, paginaAtual + 1)
                                }}
                                onClickPagina={pagina => {
                                    setPaginaAtual(pagina)
                                    carregaRevendas(registrosPorPagina, pagina)
                                }} />
                        </div>
                    </div>
                }

                <LayoutModal
                    open={confirmacaoDeletar}
                    setOpen={setConfirmacaoDeletar}
                    titulo={`Deletar ${revendaSelecionada?.descricao}`}
                    descricao={`Deseja realmente deletar o Revenda ${revendaSelecionada?.codigo}?`}
                    textoBotao="Deletar"
                    acaoBotao={confirmDeleteRevenda}
                />
            </>
        )
    }

}