import React, { useState, useEffect } from 'react';
import verificaAcesso from '../../utils/verificaAcesso';
import { autorizaDropbox, getConfiguracao } from '../../services/configuracao';
import { toast } from 'react-toastify';

function AutorizacaoDropbox() {

    const desc_funcionalidade = "CONFIGURACAO";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')
    const [etapaAtual, setEtapaAtual] = useState(1)
    const [authorizationCode, setAuthorizationCode] = useState("")

    const [configuracao, setConfiguracao] = useState({})
    const [loading, setLoading] = useState(false);

    const steps = [
        { id: 1, etapa: 'Etapa 1', name: 'Autorização', action: () => setEtapaAtual(1), status: 'current' },
        { id: 2, etapa: 'Etapa 2', name: 'Registrar Token', action: () => setEtapaAtual(2), status: 'upcoming' },
        { id: 3, etapa: 'Etapa 3', name: 'Concluído', action: () => setEtapaAtual(3), status: 'upcoming' },
    ]

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso("configuracao",
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])


    async function verificaAutorizado() {
        setLoading(true)

        const response = await getConfiguracao()
        if (response.sucesso) {
            if (response.data == null) return;
            setConfiguracao(response.data)

            if (response.data.dropboxRefreshToken && response.data.dropboxAccessToken) {
                setEtapaAtual(3)
            }
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    async function verificaCodigoAutorizacao() {
        setLoading(true)

        const response = await autorizaDropbox(authorizationCode)
        if (response.sucesso) {
            setEtapaAtual(3)
            toast.success(`Autorizado com sucesso!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                verificaAutorizado()
            } else {
                setLoading(false)
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    return (
        <div className="flex flex-col">
            <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
                <section aria-labelledby="connectionName-heading">
                    <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">

                        <div className="px-8 py-5">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Autorização Dropbox
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Status atual e Etapas de autorização de uso Dropbox.</p>
                        </div>

                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <nav aria-label="Progress">
                                <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
                                    {steps.map((step) => (
                                        <li key={step.name} className="md:flex-1">
                                            {etapaAtual >= step.id ? (
                                                <button
                                                    onClick={() => setEtapaAtual(step.id)}
                                                    className="group pl-4 py-2 w-full flex flex-col border-l-4 border-indigo-600 hover:border-indigo-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                                                >
                                                    <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800">
                                                        {step.etapa}
                                                    </span>
                                                    <span className="text-sm font-medium">{step.name}</span>
                                                </button>
                                            ) : (
                                                <div
                                                    className="group pl-4 py-2 w-full flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                                                >
                                                    <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
                                                        {step.etapa}
                                                    </span>
                                                    <span className="text-sm font-medium">{step.name}</span>
                                                </div>
                                            )}
                                        </li>
                                    ))}
                                </ol>
                            </nav>
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-1 pt-20 pb-10">
                                {etapaAtual === 1 &&
                                    <div className='flex flex-col items-center text-center space-y-6'>
                                        <label>Acesse o
                                            <a className='text-blue-600 font-semibold px-1' target="_blank" rel="noreferrer" href={`https://www.dropbox.com/oauth2/authorize?client_id=${configuracao.dropboxAppKey}&token_access_type=offline&response_type=code`}>Link de Autorização</a>
                                            para obter o código de integração com o Dropbox
                                        </label>
                                        <a target="_blank" rel="noreferrer" href={`https://www.dropbox.com/oauth2/authorize?client_id=${configuracao.dropboxAppKey}&token_access_type=offline&response_type=code`} className='shadow bg-gray-200 rounded-md px-6 py-4 font-semibold text-gray-600 tex-xs break-words sm:w-fit w-full'>{`https://www.dropbox.com/oauth2/authorize?client_id=${configuracao.dropboxAppKey}&token_access_type=offline&response_type=code`}</a>

                                        <button
                                            type="button"
                                            onClick={() => setEtapaAtual(2)}
                                            disabled={loading}
                                            className={`${(loading) ? "cursor-not-allowed" : "cursor-pointer"} inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500`}
                                        >
                                            Já possuo o código
                                        </button>
                                    </div>
                                }

                                {etapaAtual === 2 &&
                                    <div className='flex flex-col items-center text-center space-y-6'>
                                        <label htmlFor="authorizationCode" className="block font-medium">
                                            Código de Autorização
                                        </label>
                                        <input
                                            id="authorizationCode"
                                            name="authorizationCode"
                                            defaultValue={authorizationCode}
                                            onChange={(e) => setAuthorizationCode(e.target.value)}
                                            type="text"
                                            className="mt-1 block sm:w-1/2 w-full border border-gray-300 rounded-md shadow-sm py-4 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />

                                        <button
                                            type="button"
                                            onClick={verificaCodigoAutorizacao}
                                            disabled={loading}
                                            className={`${(loading) ? "cursor-not-allowed" : "cursor-pointer"} inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500`}
                                        >
                                            Verificar Código
                                        </button>
                                    </div>
                                }

                                {etapaAtual === 3 && <div>
                                    <div className='flex flex-col items-center text-center space-y-6'>
                                        <p className='text-green-600 text-2xl font-bold'>
                                            Dropbox autorizado
                                        </p>
                                    </div>
                                </div>}
                            </dl>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    )
}

export default AutorizacaoDropbox;