import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ReactECharts from 'echarts-for-react';
import { getResumoMensalCLiente } from '../../services/cliente';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function ResumoMensalCliente() {
  const [dadosMensais, setDadosMensais] = useState(null)

  async function carregaSessoesAtivaPeriodo() {
    const response = await getResumoMensalCLiente();

    if (response.sucesso) {
      const labels = response.data.map(sessao => sessao.data.split('-')[1] + '/' + sessao.data.split('-')[0])
      const quantidadeAplicacao = response.data.map(sessao => sessao.quantidadeAplicacao)
      const quantidadeCancelamento = response.data.map(sessao => sessao.quantidadeCancelamento)
      
      const mediaApplicacao = quantidadeAplicacao.map(q => Math.floor(quantidadeAplicacao.reduce((a, b) => a + b, 0) / quantidadeAplicacao.length))
      
      const mediaCancelmento = quantidadeCancelamento.map(q => Math.floor(quantidadeCancelamento.reduce((a, b) => a + b, 0) / quantidadeCancelamento.length))

      const options = {
        grid: { top: 8, right: 40, bottom: 24, left: 40 },
        xAxis: {
          type: 'category',
          data: labels,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: quantidadeAplicacao,
            type: 'bar',
            name: 'Contratação',
            color: '#91cc75',
            smooth: false,
          },
          {
            data: quantidadeCancelamento,
            type: 'bar',
            name: 'Cancelamento',
            color: '#ee6666',
            smooth: false,
          }, {
            data: mediaApplicacao,
            type: 'line',
            name: 'Média Contratação',
            color: '#91cc7588',
            smooth: true,
          }, {
            data: mediaCancelmento,
            type: 'line',
            name: 'Média Cancelamento',
            color: '#ee666688',
            smooth: true,
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
      };

      setDadosMensais(options)
    }
  }

  useEffect(() => {
    carregaSessoesAtivaPeriodo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='w-full'>
      {dadosMensais && <ReactECharts option={dadosMensais} />}
    </div>
  )
}
