import React, { useState, useEffect } from 'react'
import { getListClientes } from '../../../services/mde'
import { getSelectCliente } from '../../../services/cliente'
import { useNavigate } from "react-router-dom";
import LayoutPaginacaoTabela from "../../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../../components/EmptyPage";
import LoadingPage from "../../../components/LoadingPage";
import verificaAcesso from '../../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import { SearchIcon } from '@heroicons/react/outline'
import { CheckCircleIcon, CheckIcon, ClockIcon, ExclamationIcon, MinusCircleIcon, ViewListIcon } from '@heroicons/react/solid';
import API_URL from '../../../utils/api';
import useDebounce from '../../../hooks/useDebounce';
import Select from 'react-select';
import { formatarDataHora } from '../../../utils/formartar';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function MDECliente(props) {
    let navigate = useNavigate();
    //acessos
    const desc_funcionalidade = "MDE_CLIENTE";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')

    const [listaMDECliente, setListaMDECliente] = useState([])

    const [loading, setLoading] = useState(true)

    //Filtros
    const [filtroPesquisa, setFiltroPesquisa] = useState(null);
    const [filtroTotalizador, setFiltroTotalizador] = useState(null);
    const [quantidadeTotal, setQuantidadeTotal] = useState(0);
    const [quantidadeAtualizado, setQuantidadeAtualizado] = useState(0);
    const [quantidadePendente, setQuantidadePendente] = useState(0);
    const [quantidadeExpirado, setQuantidadeExpirado] = useState(0);

    const [filtroCliente, setFiltroCliente] = useState({ value: null, label: "Todas" })
    const [opcoesCliente, setOpcoesCliente] = useState([]);
    const clienteDebounce = useDebounce(carregaOpcoesCliente, 500)
    const [pesquisaCliente, setPesquisaCliente] = useState("")

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroPesquisa, filtroCliente, filtroTotalizador])

    async function carregaOpcoesCliente(pesquisa = pesquisaCliente) {
        const response = await getSelectCliente(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(cliente => {
                return {
                    value: cliente.id,
                    label: cliente.descricao
                }
            })
            setOpcoesCliente([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = props.quantidadeResultados || 5

    const DadosMDELinha = ({ dadosLinha }) => {
        const {
            cliente,
            dataUltimaExecucao,
            statusUltimaExecucao,
            quantidadeResumos,
            quantidadeEventos,
            quantidadeNotasFiscais,
            // dataUltimaAlteracao,
            // usuarioUltimaAlteracao,
        } = dadosLinha

        const documentoMask = (d) => {
            if (cliente.tipoPessoa === "PF" || d.length <= 11) {
                return d.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            } else if (cliente.tipoPessoa === "PJ" || d.length > 11) {
                return d.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/, '$1.$2.$3/$4-$5');
            }
        }

        return (
            <>
                <div className=" lg:col-span-2 col-span-auto flex flex-row flex-wrap sm:flex-nowrap shrink-0">
                    <img
                        className="h-12 w-12 mb-2 mr-4 rounded-lg object-contain"
                        src={cliente.arquivoLogoId ? `${API_URL}/arquivo/download/${cliente.arquivoLogoId}` : require('../../../assets/images/user.png')}
                        alt=""
                    />
                    <div className="grid-cols-1 lg:grid-cols-2 grid flex-1">
                        <div className="text-sm font-medium text-gray-800 flex capitalize">
                            <p className='break-words print:text-xs mr-1'>{cliente.id} - {cliente.nomeFantasia}</p>
                        </div>
                        <div className="flex text-sm text-gray-500">
                            <p className='break-words print:text-xs mr-1'>{documentoMask(cliente.documento)}</p>
                        </div>
                        <div className="text-sm font-medium text-gray-800 capitalize flex">
                            <p className='break-words print:text-xs mr-1'>{cliente.razaoSocial}</p>
                        </div>
                    </div>
                </div>



                <div className='flex flex-col gap-1 col-span-2'>
                    <div className={`text-sm font-medium text-gray-800 truncate flex`}>
                        <p className='truncate mr-1'>Última Execução: {formatarDataHora(dataUltimaExecucao)}</p>
                    </div>
                    <div className='flex flex-row items-center text-center gap-2 flex-wrap'>
                        {statusUltimaExecucao === "Expirada" ?
                            <span className='rounded-lg bg-red-600/20 text-red-600 px-2 py-1 font-semibold text-sm'>Expirada</span>
                            :
                            statusUltimaExecucao === "Pendente" ?
                                <span className='rounded-lg bg-orange-600/20 text-orange-600 px-2 py-1 font-semibold text-sm'>Pendente</span>
                                :
                                statusUltimaExecucao === "Atualizada" ?
                                    <span className='rounded-lg bg-green-600/20 text-green-600 px-2 py-1 font-semibold text-sm'>Atualizada</span>
                                    :
                                    <span className='rounded-lg bg-gray-600/20 text-gray-600 px-2 py-1 font-semibold text-sm'>Não informada</span>
                        }
                        <span className={classNames(quantidadeResumos > 0 ? "bg-orange-600/20 text-orange-600 shadow" : "bg-gray-600/10 text-gray-400", 'flex-1 whitespace-nowrap rounded-lg px-2 py-1 font-semibold text-sm')}>{quantidadeResumos} {quantidadeResumos === 1 ? "Resumo" : "Resumos"}</span>
                        <span className={classNames(quantidadeEventos > 0 ? "bg-yellow-600/20 text-yellow-600 shadow" : "bg-gray-600/10 text-gray-400", 'flex-1 whitespace-nowrap rounded-lg px-2 py-1 font-semibold text-sm')}>{quantidadeEventos} {quantidadeEventos === 1 ? "Evento" : "Eventos"}</span>
                        <span className={classNames(quantidadeNotasFiscais > 0 ? "bg-blue-600/20 text-blue-600 shadow px-2" : "bg-gray-600/10 text-gray-400", 'flex-1 whitespace-nowrap rounded-lg px-2 py-1 font-semibold text-sm')}>{quantidadeNotasFiscais} {quantidadeNotasFiscais === 1 ? "Nota Fiscal" : "Notas Fiscais"}</span>
                    </div>
                </div>
            </>
        )
    }

    const LinhaTabela = ({ dadosLinha }) => {
        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-2 sm:px-6">
                    <div className="grid xl:grid-cols-4 grid-cols-1 gap-2">
                        <DadosMDELinha dadosLinha={dadosLinha} />
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso("mde", //location.pathname.split("/")[1]
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaClientesMDE(registrosPorPagina, paginaAtual)
                carregaOpcoesCliente()
            }
            else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaClientesMDE = async (pageSize = registrosPorPagina, currentPage = 0) => {
        setLoading(true);

        const filtros = {
            pageSize,
            currentPage,
            MDEId: props.MDEId,
            pesquisa: filtroPesquisa,
            clienteId: filtroCliente.value,
            filtroTotalizador,
        }
        if (props.chaveNotaFiscal) filtros.chaveNotaFiscal = props.chaveNotaFiscal

        const response = await getListClientes(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.dadosListagem.currentPage)
            setTotalRegistros(response.data.dadosListagem.totalRegisters)
            setTotalPaginas(response.data.dadosListagem.totalPages)

            setQuantidadeTotal(response.data.quantidadeTotal)
            setQuantidadeAtualizado(response.data.quantidadeAtualizado)
            setQuantidadePendente(response.data.quantidadePendente)
            setQuantidadeExpirado(response.data.quantidadeExpirado)

            setListaMDECliente(response.data.dadosListagem.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    useEffect(() => {
        if (pesquisaCliente) clienteDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaCliente])

    function handleSelectCliente(clienteSelecionado) {
        setFiltroCliente(clienteSelecionado)
    }

    const filtroDebounce = useDebounce(carregaClientesMDE, 500)

    const renderLinhasTabela = () => {
        return listaMDECliente.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <div className={`flex flex-col`}>
                {props.filtros && <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8'>
                    <div>
                        <label htmlFor="country" className="text-sm mb-1 font-medium text-gray-700 flex items-center">
                            Pesquisa
                            <i className='text-xs text-gray-400 ml-4'>
                                ( Razão Social, Nome Fantasia, CNPJ )
                            </i>
                        </label>
                        <input
                            defaultValue={filtroPesquisa}
                            onChange={e => setFiltroPesquisa(e.target.value)}
                            type="text"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Pesquisa " />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                            Cliente
                        </label>
                        <Select
                            isDisabled={loading}
                            name="cliente"
                            value={filtroCliente}
                            onChange={handleSelectCliente}
                            onInputChange={value => setPesquisaCliente(value)}
                            options={opcoesCliente}
                            placeholder="Selecione"
                            noOptionsMessage={() => "Nenhuma Cliente disponível"}
                            className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>}

                {props.filtros && <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-8'>
                    <div onClick={() => setFiltroTotalizador(null)}
                        className={classNames(
                            filtroTotalizador == null ? 'border-2 border-gray-500' : 'border-2 border-white',
                            "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                        )}>
                        <dt>
                            <div className="absolute bg-gray-500 rounded-md p-3">
                                <ViewListIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <div className={classNames(filtroTotalizador == null ? "text-gray-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                <p className='truncate'>Todos</p>
                                {filtroTotalizador == null ?
                                    <CheckCircleIcon className="shrink-0 text-gray-500 h-5 w-5 m-0" aria-hidden="true" />
                                    : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-5 m-0" aria-hidden="true" />
                                }
                            </div>
                        </dt>
                        <dd className="ml-16">
                            <p className="text-2xl font-semibold text-gray-900">{quantidadeTotal}</p>
                        </dd>
                    </div>
                    <div onClick={() => setFiltroTotalizador('atualizado')}
                        className={classNames(
                            filtroTotalizador === "atualizado" ? 'border-2 border-green-500' : 'border-2 border-white',
                            "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                        )}>
                        <dt>
                            <div className="absolute bg-green-500 rounded-md p-3">
                                <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <div className={classNames(filtroTotalizador === "atualizado" ? "text-green-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                <p className='truncate'>Atualizados</p>
                                {filtroTotalizador === "atualizado" ?
                                    <CheckCircleIcon className="shrink-0 text-green-500 h-5 w-5 m-0" aria-hidden="true" />
                                    : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-5 m-0" aria-hidden="true" />
                                }
                            </div>
                        </dt>
                        <dd className="ml-16">
                            <p className="text-2xl font-semibold text-gray-900">{quantidadeAtualizado}</p>
                        </dd>
                    </div>
                    <div onClick={() => setFiltroTotalizador('pendente')}
                        className={classNames(
                            filtroTotalizador === "pendente" ? 'border-2 border-orange-500' : 'border-2 border-white',
                            "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                        )}>
                        <dt>
                            <div className="absolute bg-orange-500 rounded-md p-3">
                                <ClockIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <div className={classNames(filtroTotalizador === "pendente" ? "text-orange-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                <p className='truncate'>Pendentes</p>
                                {filtroTotalizador === "pendente" ?
                                    <CheckCircleIcon className="shrink-0 text-orange-500 h-5 w-5 m-0" aria-hidden="true" />
                                    : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-5 m-0" aria-hidden="true" />
                                }
                            </div>
                        </dt>
                        <dd className="ml-16">
                            <p className="text-2xl font-semibold text-gray-900">{quantidadePendente}</p>
                        </dd>
                    </div>
                    <div onClick={() => setFiltroTotalizador('expirado')}
                        className={classNames(
                            filtroTotalizador === "expirado" ? 'border-2 border-red-500' : 'border-2 border-white',
                            "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                        )}>
                        <dt>
                            <div className="absolute bg-red-500 rounded-md p-3">
                                <ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <div className={classNames(filtroTotalizador === "expirado" ? "text-red-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                <p className='truncate'>Expirados</p>
                                {filtroTotalizador === "expirado" ?
                                    <CheckCircleIcon className="shrink-0 text-red-500 h-5 w-5 m-0" aria-hidden="true" />
                                    : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-5 m-0" aria-hidden="true" />
                                }
                            </div>
                        </dt>
                        <dd className="ml-16">
                            <p className="text-2xl font-semibold text-gray-900">{quantidadeExpirado}</p>
                        </dd>
                    </div>
                </div>}

                <div className="bg-white sm:rounded-lg sm:shadow my-8">
                    <div className="bg-white sm:rounded-t-lg px-4 py-5 border-b border-gray-200 sm:px-6">
                        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    {props.titulo || "Clientes"}
                                </h3>
                            </div>
                        </div>
                    </div>

                    {!listaMDECliente ?
                        <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                            <EmptyPage texto="Nenhuma MDE Encontrada" Icone={SearchIcon} />
                        </div>
                        :
                        <ul className="divide-y divide-gray-200">
                            {renderLinhasTabela()}
                        </ul>
                    }

                    <LayoutPaginacaoTabela
                        pagina={paginaAtual}
                        totalRegistros={totalRegistros}
                        registrosPorPagina={registrosPorPagina}
                        totalPaginas={totalPaginas}
                        className="rounded-b-lg"
                        onClickPaginaAnterior={() => {
                            setPaginaAtual(paginaAtual - 1)
                            carregaClientesMDE(registrosPorPagina, paginaAtual - 1)
                        }}
                        onClickPaginaPosterior={() => {
                            setPaginaAtual(paginaAtual + 1)
                            carregaClientesMDE(registrosPorPagina, paginaAtual + 1)
                        }}
                        onClickPagina={pagina => {
                            setPaginaAtual(pagina)
                            carregaClientesMDE(registrosPorPagina, pagina)
                        }} />
                </div>
            </div>
        )
    }

}