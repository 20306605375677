import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import Login from '../pages/Login'
import UsuarioListagem from '../pages/UsuarioListagem'
import UsuarioForm from '../pages/UsuarioForm'
import PerfilAcessoForm from "../pages/PerfilAcessoForm";
import PerfilAcesso from "../pages/PerfilAcesso";
import LayoutDashboard from "../components/LayoutDashboard";
import NcmListagem from "../pages/NcmListagem";
import Configuracao from "../pages/Configuracao";
import NcmForm from "../pages/NcmForm";
import Dashboard from "../pages/Dashboard";
import { autenticacao } from "../services/auth";
import ClienteListagem from "../pages/ClienteListagem";
import ClienteForm from "../pages/ClienteForm";
import RevendaListagem from "../pages/RevendaListagem";
import RevendaForm from "../pages/RevendaForm";
import PlanoListagem from "../pages/PlanoListagem";
import PlanoForm from "../pages/PlanoForm";
import ContadorListagem from "../pages/ContadorListagem";
import ContadorForm from "../pages/ContadorForm";
import ConsultaClienteListagem from "../pages/ConsultaClienteListagem";
import ArquivoClienteListagem from "../pages/ArquivoClienteListagem";
import LeadListagem from "../pages/LeadListagem";
import AnaliseDeUso from "../pages/AnaliseDeUso";
import SolicitacaoDownloadListagem from "../pages/SolicitacaoDownload";
import MDE from "../pages/MDE";
import ClienteBackup from "../pages/ClienteBackup";

function Router() {
    async function verificaToken() {
        const token = localStorage.getItem('@admin_Token');

        if (!token && window.location.pathname !== "/login") {
            window.location.href = '/login';
        } else if (window.location.pathname !== "/login") {
            const response = await autenticacao()

            if (response.sucesso) {
                localStorage.setItem('@admin_Token', response.data.token);
            } else {
                localStorage.removeItem('@admin_Token');
                window.location.href = '/login';
            }
        }
    }

    useEffect(() => {
        (async () => {
            await verificaToken();
        }
        )();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const routes = [
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/",
            element: <LayoutDashboard />,
            children: [
                {
                    path: "/",
                    element: <Dashboard />,
                },
                {
                    path: "Usuario",
                    element: <UsuarioListagem />,
                },
                {
                    path: "Usuario/Form",
                    element: <UsuarioForm />,
                },
                {
                    path: "perfil-acesso",
                    element: <PerfilAcesso />,
                },
                {
                    path: "perfil-acesso/Form",
                    element: <PerfilAcessoForm />,
                },
                {
                    path: "Ncm",
                    element: <NcmListagem />,
                },
                {
                    path: "Ncm/Form",
                    element: <NcmForm />,
                },
                {
                    path: "Cliente",
                    element: <ClienteListagem />,
                },
                {
                    path: "Cliente/Form",
                    element: <ClienteForm />,
                },
                {
                    path: "Revenda",
                    element: <RevendaListagem />,
                },
                {
                    path: "Revenda/Form",
                    element: <RevendaForm />,
                },
                {
                    path: "Plano",
                    element: <PlanoListagem />,
                },
                {
                    path: "Plano/Form",
                    element: <PlanoForm />,
                },
                {
                    path: "Contador",
                    element: <ContadorListagem />,
                },
                {
                    path: "Contador/Form",
                    element: <ContadorForm />,
                },
                {
                    path: "cliente-backup",
                    element: <ClienteBackup />,
                },
                {
                    path: "Configuracao",
                    element: <Configuracao />,
                },
                {
                    path: "Consulta-cliente",
                    element: <ConsultaClienteListagem />,
                },
                {
                    path: "Documento-fiscal",
                    element: <ArquivoClienteListagem />,
                },
                {
                    path: "Lead",
                    element: <LeadListagem />,
                },
                {
                    path: "Analise-de-uso",
                    element: <AnaliseDeUso />,
                },
                {
                    path: "solicitacao-download",
                    element: <SolicitacaoDownloadListagem />,
                },
                {
                    path: "mde",
                    element: <MDE />,
                },
            ],
        },
    ];

    return useRoutes(routes);
}

export default Router;
