import React, { useState, useEffect } from 'react';
import verificaAcesso from '../../utils/verificaAcesso';
import { definirConfiguracao, getConfiguracao } from '../../services/configuracao';
import { toast } from 'react-toastify';

function FormConfiguracaoGeral() {
    const desc_funcionalidade = "CONFIGURACAO";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')
    const [editar, setEditar] = useState('')

    const [configuracao, setConfiguracao] = useState({})
    const [caminhoAnexo, setCaminhoAnexo] = useState("")
    const [dropboxAppKey, setDropboxAppKey] = useState("")
    const [dropboxAppSecret, setDropboxAppSecret] = useState("")
    
    const [salvando, setSalvando] = useState(false)

    async function carregaConfiguracao() {
        const response = await getConfiguracao()
        if (response.sucesso) {
            if (response.data == null) return;

            setConfiguracao(response.data)
            setCaminhoAnexo(response.data.pathArquivos)
            setDropboxAppKey(response.data.dropboxAppKey)
            setDropboxAppSecret(response.data.dropboxAppSecret)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function handleSalvar() {
        setSalvando(true)

        const config = configuracao || {};

        config.pathArquivos = caminhoAnexo;
        config.dropboxAppKey = dropboxAppKey;
        config.dropboxAppSecret = dropboxAppSecret;

        const response = await definirConfiguracao(config)

        if (response.sucesso) {
            toast.success(`Configurações atualizadas`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setSalvando(false)
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso("configuracao",
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
                { acao: setEditar, nome: desc_funcionalidade + "_EDITAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaConfiguracao()
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const renderAlterarCaminhoAnexo = () => {
        return (
            <>
                <div className="sm:col-span-2">
                    <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                            Caminho Anexo
                        </label>
                        <input
                            id="caminhoAnexo"
                            name="caminhoAnexo"
                            defaultValue={caminhoAnexo}
                            disabled={salvando}
                            onChange={(e) => setCaminhoAnexo(e.target.value)}
                            type="text"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>
            </>
        )
    }

    const renderDropboxAppKey = () => {
        return (
            <div className="sm:col-span-2">
                <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                        Dropbox App Key
                    </label>
                    <input
                        id="dropboxAppKey"
                        name="dropboxAppKey"
                        defaultValue={dropboxAppKey}
                        disabled={salvando}
                        onChange={(e) => setDropboxAppKey(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
            </div>
        )
    }

    const renderDropboxAppSecret = () => {
        return (
            <div className="sm:col-span-2">
                <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                        Dropbox App Secret
                    </label>
                    <input
                        id="dropboxAppSecret"
                        name="dropboxAppSecret"
                        defaultValue={dropboxAppSecret}
                        disabled={salvando}
                        onChange={(e) => setDropboxAppSecret(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col">
            <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
                <section aria-labelledby="connectionName-heading">
                    <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">

                        <div className="px-8 py-5">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Configurações Gerais
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Caminho Anexo e outras configurações.</p>
                        </div>

                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                                {renderAlterarCaminhoAnexo()}
                                {renderDropboxAppKey()}
                                {renderDropboxAppSecret()}
                            </dl>
                        </div>

                        <div className="m-6 pb-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                            {editar && <button
                                type="button"
                                onClick={handleSalvar}
                                disabled={(salvando)}
                                className={`${(salvando) ? "cursor-not-allowed" : "cursor-pointer"} inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500`}
                            >
                                {salvando && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>}
                                Salvar Alterações
                            </button>}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default FormConfiguracaoGeral;