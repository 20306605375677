import instance from '../configAxios';

export const getCadastroLeadById = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/lead/${id}`)
    return { sucesso: response.status, data: response.data }
  } catch (error) {
    return { sucesso: false, mensagem: error.response.data };
  }
}

export const getCadastroLeadLogado = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/lead/logado`)
    return { sucesso: response.status, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListCadastroLead = async (filtros) => {
  const axios = await instance();

  try {
    const response = await axios.post(`/lead/listagem`, filtros)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {

      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const postCadastroLead = async novoCadastroLead => {
  const axios = await instance();

  try {
    const response = await axios.post(`/lead`, novoCadastroLead)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const putCadastroLead = async (idCadastroLead, cadastroLeadEditado) => {
  const axios = await instance();

  try {
    const response = await axios.put(`/lead/${idCadastroLead}`, cadastroLeadEditado)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const deletarCadastroLead = async idCadastroLead => {
  const axios = await instance();

  try {
    const response = await axios.delete(`/lead/${idCadastroLead}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListaCadastroLead = async (pesquisa) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/lead/select?pesquisa=${pesquisa}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}