import React from 'react'
import { ClipboardIcon } from '@heroicons/react/solid'
import EmptyPage from "../EmptyPage";

export default function TabelaResumo({ listaDados }) {

    const LinhaTabela = (props) => {
        const {
            grupo,
            quantidade
        } = props.dadosLinha

        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-4 sm:px-6">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="text-sm font-medium text-gray-800 truncate capitalize">
                            <p className='truncate mr-1'>{grupo.toLowerCase()}</p>
                        </div>
                        <div className="text-sm font-medium text-gray-500 flex truncate justify-end">
                            <p className='truncate'>{quantidade}</p>
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    const renderLinhasTabela = () => {
        return listaDados.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    return (
        <>
            {!listaDados ?
                <div className="flex flex-col">
                    <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
                        <EmptyPage texto="Nenhuma Dados Cadastrado" Icone={ClipboardIcon} />
                    </div>
                </div>
                :
                <div className="flex flex-col">
                    <div className="bg-white sm:rounded-lg sm:shadow">
                        <ul className="divide-y divide-gray-200">
                            {renderLinhasTabela()}
                        </ul>
                    </div>
                </div>
            }

        </>
    )
}