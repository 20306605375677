import instance from '../configAxios';

export const getConsultaClienteById = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/consultaCliente/${id}`)
    return { sucesso: response.status, data: response.data}
  } catch (error) {
    return { sucesso: false, mensagem:error.response.data };
  }
}

export const getListConsultaCliente = async (filtros) => {
  const axios = await instance();
  
  try {
    const response =  await axios.post(`/consultaCliente/listagem`, filtros)
    return { sucesso: true, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}


export const getResumoConsultaCLiente = async (filtro) => {
  const axios = await instance();

  try {
    const response = await axios.post(`/consultaCliente/resumo`, filtro)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}