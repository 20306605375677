import React, { useState, useEffect, Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon, ClipboardIcon, EyeIcon } from '@heroicons/react/solid'
import { getListCadastroLead } from '../../services/cadastroLead.js'
import { useNavigate, useLocation } from "react-router-dom";
import LayoutPaginacaoTabela from "../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import useDebounce from '../../hooks/useDebounce';
import ModalLead from '../../components/ModalLead'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LeadListagem() {
    let navigate = useNavigate();
    const location = useLocation();

    //acessos
    const desc_funcionalidade = "LEAD";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')

    const [listaCadastroLead, setListaCadastroLead] = useState([])
    const [cadastroLeadSelecionado, setCadastroLeadSelecionado] = useState({})
    const [modalVisualizar, setModalVisualizar] = useState(false)

    const [loading, setLoading] = useState(true)

    //Filtros
    const [filtroPesquisa, setFiltroPesquisa] = useState(null);

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroPesquisa])

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = 10

    function handleVisualizarCadastroLead(lead) {
        setCadastroLeadSelecionado(lead)
        setModalVisualizar(true)
        // navigate(`/cadastroLead/form`, { state: { "idCadastroLead": id } })
    }

    const LinhaTabela = (props) => {
        const {
            id,
            nome,
            email,
            telefone,
            empresa
        } = props.dadosLinha

        const celularMask = (c) => {
            if (c.length < 11) {
                return c.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
            } else {
                return c.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
            }
        }

        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-4 sm:px-6">
                    <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
                        <div className="text-sm font-medium text-gray-800 truncate">
                            <p className='truncate mr-1'>{id} - {nome}</p>
                        </div>

                        <div className='flex-1 shrink'>
                            <div className="flex items-center justify-between">
                                <div className="text-sm font-medium text-gray-800 truncate">
                                    <p className='truncate mr-1'>{email}</p>
                                </div>
                            </div>
                            <div className="mt-1 flex justify-between">
                                <div className="flex items-center text-sm text-gray-500 truncate">
                                    <p className='truncate mr-1'>{telefone && celularMask(telefone)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="self-center justify-between flex">
                            <div className="flex justify-between">
                                <div className="text-sm font-medium text-gray-800 flex truncate">
                                    <p className='truncate mr-1'>Empresa</p>: {empresa || <span className='ml-1 text-gray-400'>Não informado</span>}
                                </div>
                            </div>
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                        <span className="sr-only">Open options</span>
                                        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-30 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {listar && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={e => handleVisualizarCadastroLead(props.dadosLinha)}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        <EyeIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        <span>Ver Cadastro Lead</span>
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso(location.pathname.split("/")[1],
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaCadastroLeads(registrosPorPagina, paginaAtual)
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaCadastroLeads = async (pageSize = registrosPorPagina, currentPage = 0) => {
        const filtros = {
            pageSize,
            currentPage,
            pesquisa: filtroPesquisa,
        }

        const response = await getListCadastroLead(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.currentPage)
            setTotalRegistros(response.data.totalRegisters)
            setTotalPaginas(response.data.totalPages)

            setListaCadastroLead(response.data.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const filtroDebounce = useDebounce(carregaCadastroLeads, 500)

    const renderLinhasTabela = () => {
        return listaCadastroLead.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <>
                {!listaCadastroLead ?
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <EmptyPage texto="Nenhuma CadastroLead Cadastrado" Icone={ClipboardIcon} />
                        </div>
                    </div>
                    :
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8'>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700 flex items-center">
                                    Pesquisa
                                    <i className='text-xs text-gray-400 ml-4'>
                                        ( Id,
                                        Descrição )
                                    </i>
                                </label>
                                <input
                                    defaultValue={filtroPesquisa}
                                    onChange={e => setFiltroPesquisa(e.target.value)}
                                    type="text"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Pesquisa " />
                            </div>
                        </div>
                        <div className="bg-white sm:rounded-lg sm:shadow my-8">
                            <div className="bg-white sm:rounded-t-lg px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Lead
                                        </h3>
                                    </div>
                                    <div className="ml-4 mt-2 flex-shrink-0">

                                    </div>
                                </div>
                            </div>

                            <ul className="divide-y divide-gray-200">
                                {renderLinhasTabela()}
                            </ul>

                            <LayoutPaginacaoTabela
                                pagina={paginaAtual}
                                totalRegistros={totalRegistros}
                                registrosPorPagina={registrosPorPagina}
                                totalPaginas={totalPaginas}
                                className="rounded-b-lg"
                                onClickPaginaAnterior={() => {
                                    setPaginaAtual(paginaAtual - 1)
                                    carregaCadastroLeads(registrosPorPagina, paginaAtual - 1)
                                }}
                                onClickPaginaPosterior={() => {
                                    setPaginaAtual(paginaAtual + 1)
                                    carregaCadastroLeads(registrosPorPagina, paginaAtual + 1)
                                }}
                                onClickPagina={pagina => {
                                    setPaginaAtual(pagina)
                                    carregaCadastroLeads(registrosPorPagina, pagina)
                                }} />
                        </div>
                    </div>
                }

                <ModalLead
                    open={modalVisualizar}
                    setOpen={setModalVisualizar}
                    tituloModal={"Visualizar Lead"}
                    lead={cadastroLeadSelecionado}
                />
            </>
        )
    }

}