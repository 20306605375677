import instance from '../configAxios';

export const getPlanoById = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/plano/${id}`)
    return { sucesso: response.status, data: response.data }
  } catch (error) {
    return { sucesso: false, mensagem: error.response.data };
  }
}

export const getPlanoLogado = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/plano/logado`)
    return { sucesso: response.status, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListPlano = async (filtros) => {
  const axios = await instance();

  try {
    const response = await axios.post(`/plano/listagem`, filtros)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const postPlano = async novoPlano => {
  const axios = await instance();

  try {
    const response = await axios.post(`/plano`, novoPlano)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const putPlano = async (idPlano, planoEditado) => {
  const axios = await instance();

  try {
    const response = await axios.put(`/plano/${idPlano}`, planoEditado)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const alterarSenhaPlano = async (id, novaSenha) => {
  const axios = await instance();

  try {
    const response = await axios.patch(`/plano/alterarSenha/${id}`, novaSenha)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const deletarPlano = async idPlano => {
  const axios = await instance();

  try {
    const response = await axios.delete(`/plano/${idPlano}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListaPlano = async (pesquisa) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/plano/select?pesquisa=${pesquisa}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}