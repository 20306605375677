import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import Select from 'react-select';
import LoadingPage from "../../components/LoadingPage";
import { getClienteById, postCliente, putCliente } from "../../services/cliente";
import { getListMunicipiosPorUf, getListUf } from "../../services/endereco";
import LayoutSwitch from "../../components/LayoutSwitch";
import Dropzone from '../../components/Dropzone'
import API_URL from "../../utils/api";
import { uploadArquivo } from "../../services/arquivo";
import useDebounce from "../../hooks/useDebounce";
import { getListaPlano } from "../../services/plano";
import { getSelectRevenda } from "../../services/revenda";
import { getSelectContador } from "../../services/contador";
import InputMask from 'react-input-mask';
import Obrigatiorio from "../../components/Obrigatiorio";
import ConsultaClienteListagem from "../ConsultaClienteListagem";
import CurrencyInput from 'react-currency-input-field';

export default function ClienteForm() {
    let navigate = useNavigate();
    const location = useLocation();

    let idCliente = location.state ? location.state.idCliente : null;

    const salvarDebounce = useDebounce(handleSalvarCliente, 500)

    //acessos
    const desc_funcionalidade = "CLIENTE";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')

    const [loading, setLoading] = useState(true);
    const [salvandoCadastro, setSalvandoCadastro] = useState(false);

    const [form, setForm] = useState({})
    const [foto, setFoto] = useState('')
    const [foto_url, setFotoUrl] = useState(null)

    const [opcoesUf, setOpcoesUf] = useState([]);
    const ufDebounce = useDebounce(carregaOpcoesUf, 500)
    const [pesquisaUf, setPesquisaUf] = useState("")
    const [opcoesMunicipio, setOpcoesMunicipio] = useState([]);
    const municipioDebounce = useDebounce(carregaOpcoesMunicipio, 500)
    const [pesquisaMunicipio, setPesquisaMunicipio] = useState("")

    const [opcoesPlano, setOpcoesPlano] = useState([]);
    const planoDebounce = useDebounce(carregaOpcoesPlano, 500)
    const [pesquisaPlano, setPesquisaPlano] = useState("")

    const [opcoesRevenda, setOpcoesRevenda] = useState([]);
    const revendaDebounce = useDebounce(carregaOpcoesRevenda, 500)
    const [pesquisaRevenda, setPesquisaRevenda] = useState("")

    const [opcoesContador, setOpcoesContador] = useState([]);
    const contadorDebounce = useDebounce(carregaOpcoesContador, 500)
    const [pesquisaContador, setPesquisaContador] = useState("")

    const [opcoesTipoCertificadoDigital, setOpcoesTipoCertificadoDigital] = useState([]);

    const [anexo, setAnexo] = useState('')
    const [anexoUrl, setAnexoUrl] = useState(null)

    const [tipoPessoa, setTipoPessoa] = useState({ value: '', label: "Selecione" })
    const opcoesTipoPessoa = [
        { value: null, label: "Selecione" },
        { value: 'PF', label: "Pessoa Física" },
        { value: 'PJ', label: "Pessoa Jurídica" }
    ];

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso(location.pathname.split("/")[1],
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                if (idCliente) {
                    carregaCliente()

                    setOpcoesTipoCertificadoDigital([
                        {
                            value: 1,
                            label: "A1"
                        },
                        {
                            value: 3,
                            label: "A3"
                        },
                    ])
                } else {
                    form.uf = { value: '', label: "Selecione" }
                    setLoading(false)
                }
                carregaOpcoesUf()
                carregaOpcoesPlano()
                carregaOpcoesRevenda()
                carregaOpcoesContador()
            } else {
                toast.error(`Sem acesso a Visualização de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])


    async function handleChangeFotoCliente(file) {
        if (file) {
            const formData = new FormData()
            formData.append('File', file)
            formData.append('Id', 0)
            formData.append('Descricao', 'FotoCliente')

            const response = await uploadArquivo(formData)
            if (response.sucesso) {
                setFoto(response.data)
                setFotoUrl(`${API_URL}/arquivo/download/${response.data.id}`)
            } else {
                toast.error(response.mensagem, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    function handleInputChange(event, selectName) {
        const formAtualizado = form;
        carregaOpcoesUf()

        if (selectName === "uf") {
            carregaOpcoesMunicipio('', event.value ? event.value : null)
        }

        if (event.label) { // caso seja um select
            formAtualizado[selectName] = event;
            setForm(formAtualizado);
            return;
        }

        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        formAtualizado[name] = value;
        setForm(formAtualizado);
    }

    const unmask = (value) => {
        if (!value) return value;
        return value.replace(/[^\wÀ-ú]/g, '')
    }

    async function carregaCliente() {
        setLoading(true)

        const response = await getClienteById(idCliente)

        if (response.sucesso) {
            response.data.dataAplicacao = response.data.dataAplicacao ? response.data.dataAplicacao.split("T")[0] : '';
            response.data.dataValidade = response.data.dataValidade ? response.data.dataValidade.split("T")[0] : '';
            response.data.uf = { value: response.data.ufId, label: response.data.uf }
            response.data.municipio = { value: response.data.municipioId, label: response.data.municipio }
            response.data.plano = { value: response.data.planoId, label: response.data.plano }
            response.data.revenda = { value: response.data.revendaId, label: response.data.revenda }
            response.data.contador = { value: response.data.contadorId, label: response.data.contador }
            response.data.tipoCertificadoDigital = { value: response.data.tipoCertificadoDigital === "A1" ? 1 : 2, label: response.data.tipoCertificadoDigital }
            setTipoPessoa({ value: response.data.tipoPessoa, label: response.data.tipoPessoa === 'PF' ? 'Pessoa Física' : 'Pessoa Jurídica' })
            if (response.data.arquivoLogoId) setFotoUrl(`${API_URL}/arquivo/download/${response.data.arquivoLogoId}`)
            if (response.data.anexoCertificadoDigitalId) setAnexoUrl(`${API_URL}/arquivo/download/${response.data.anexoCertificadoDigitalId}`)
            setAnexo(response.data.anexoCertificadoDigital)
            carregaOpcoesMunicipio('', response.data.ufId)
            setForm(response.data)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    async function carregaOpcoesUf(pesquisa = pesquisaUf) {
        const response = await getListUf(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(uf => {
                return {
                    value: uf.id,
                    label: uf.descricao
                }
            })
            setOpcoesUf([{ value: '', label: 'Selecione' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaOpcoesMunicipio(pesquisa = pesquisaMunicipio, ufId = form.uf.value) {
        let formAtualizado = form;
        formAtualizado['municipio'] = { value: '', label: "Selecione" };
        setForm(formAtualizado);

        if (!ufId) {
            setOpcoesMunicipio([])
            return
        }

        const response = await getListMunicipiosPorUf(pesquisa, ufId)
        if (response.sucesso) {
            const opcoes = response.data.map(municipio => {
                return {
                    value: municipio.id,
                    label: municipio.descricao
                }
            })
            setOpcoesMunicipio([{ value: '', label: 'Selecione' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaOpcoesPlano(pesquisa = pesquisaPlano) {
        const response = await getListaPlano(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(plano => {
                return {
                    value: plano.id,
                    label: plano.descricao
                }
            })
            setOpcoesPlano([{ value: '', label: 'Selecione' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaOpcoesRevenda(pesquisa = pesquisaRevenda) {
        const response = await getSelectRevenda(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(revenda => {
                return {
                    value: revenda.id,
                    label: revenda.descricao
                }
            })
            setOpcoesRevenda([{ value: '', label: 'Selecione' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaOpcoesContador(pesquisa = pesquisaContador) {
        const response = await getSelectContador(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(contador => {
                return {
                    value: contador.id,
                    label: contador.descricao
                }
            })
            setOpcoesContador([{ value: '', label: 'Selecione' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const verificaForm = () => {
        const obrigatiorios = [
            { valor: "dataValidade", nome: "Data Validade" },
            { valor: "dataAplicacao", nome: "Data Aplicação" },
            { valor: "municipio", nome: "Município" },
            { valor: "uf", nome: "UF" },
            { valor: "cep", nome: "CEP" },
            { valor: "revenda", nome: "Revenda" },
            { valor: "plano", nome: "plano" },
            { valor: "documento", nome: "Documento" },
            { valor: "tipoPessoa", nome: "Tipo Pessoa" },
            { valor: "nomeFantasia", nome: "Nome Fantasia" },
            { valor: "razaoSocial", nome: "Razão Social" },
        ]

        let campoInvalido = false;
        obrigatiorios.forEach(campo => {
            if (!form[campo.valor] || form[campo.valor].label === 'Selecione') {
                campoInvalido = campo.nome;
            }
        });

        return campoInvalido
    }

    async function handleSalvarCliente() {
        var campoInvalido = verificaForm()

        if (campoInvalido) {
            toast.error(`Preencha o campo de ${campoInvalido}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        setSalvandoCadastro(true)

        let response = { sucesso: false, mensagem: "Erro ao salvar Cliente" }

        if (idCliente) {
            form.id = idCliente;
            form.ufId = form.uf?.value;
            form.municipioId = form.municipio?.value;
            form.planoId = form.plano?.value;
            form.revendaId = form.revenda?.value;
            form.contadorId = form.contador?.value;
            form.tipoCertificadoDigital = form.tipoCertificadoDigital?.label;
            form.arquivoLogoId = foto.id || form.arquivoLogoId
            form.anexoCertificadoDigitalId = anexo?.id || form?.anexoCertificadoDigitalId
            form.tipoPessoa = tipoPessoa.value
            form.documento = unmask(form.documento)
            form.cep = unmask(form.cep)
            form.celular = unmask(form.celular)
            form.telefone = unmask(form.telefone)
            response = await putCliente(idCliente, form)
        } else {
            form.ativo = true;
            form.ufId = form.uf?.value;
            form.municipioId = form.municipio?.value;
            form.planoId = form.plano?.value;
            form.revendaId = form.revenda?.value;
            form.contadorId = form.contador?.value;
            form.arquivoLogoId = foto.id || form.arquivoLogoId
            form.anexoCertificadoDigitalId = anexo?.id || form?.anexoCertificadoDigitalId
            form.tipoPessoa = tipoPessoa.value
            form.documento = unmask(form.documento)
            form.cep = unmask(form.cep)
            form.celular = unmask(form.celular)
            form.telefone = unmask(form.telefone)
            form.MDEAtivo = form.mdeAtivo;
            response = await postCliente(form)
        }

        if (response.sucesso) {
            toast.success('Cliente Salvo com sucesso!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            navigate(`/cliente`)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setSalvandoCadastro(false)
    }

    useEffect(() => {
        if (pesquisaUf) ufDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaUf])

    useEffect(() => {
        if (pesquisaMunicipio) municipioDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaMunicipio])

    useEffect(() => {
        if (pesquisaPlano) planoDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaPlano])

    useEffect(() => {
        if (pesquisaRevenda) revendaDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaRevenda])

    useEffect(() => {
        if (pesquisaContador) contadorDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaContador])

    function handleChangeAtivo(clienteAtivo) {
        const { valor } = clienteAtivo

        const formAtualizado = form
        formAtualizado.ativo = valor
        setForm(formAtualizado)
    }

    function handleChangeMdeAtivo(mdeAtivo) {
        const { valor } = mdeAtivo

        const formAtualizado = form
        formAtualizado.mdeAtivo = valor
        setForm(formAtualizado)
    }

    async function handleChangeAnexoCertificadoDigital(file) {
        if (file) {
            const formData = new FormData()
            formData.append('File', file)
            formData.append('Id', 0)
            formData.append('Descricao', 'AnexoCertificadoDigital')

            const response = await uploadArquivo(formData)
            if (response.sucesso) {
                setAnexo(response.data)
                setAnexoUrl(`${API_URL}/arquivo/download/${response.data.id}`)
            } else {
                toast.error(response.mensagem, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        else {
            setAnexo(null)
            setAnexoUrl(null)

            const formAtualizado = form;
            formAtualizado["anexoCertificadoDigitalId"] = null;
            setForm(formAtualizado);
        }
    }

    if (loading) {
        return <LoadingPage />
    }

    return (
        <main className="py-8 grid grid-cols-6 sm:grid-cols-12">

            <div className="px-6 lg:col-span-4 col-span-full">
                <Dropzone
                    className="grid grid-cols-1 gap-4 justify-items-center"
                    anexo={foto}
                    preview={false}
                    texto="Clique ou arraste a Imagem desejada até aqui"
                    setAnexo={handleChangeFotoCliente} >
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                                <img
                                    className="object-contain h-20 w-20 rounded-full"
                                    src={foto_url || require('../../assets/images/user.png')}
                                    alt="Foto do Cliente"
                                />
                            </div>
                        </div>
                    </div>
                </Dropzone>
                {idCliente &&
                    <>
                        <div className="lg:block hidden col-span-full">
                            <ConsultaClienteListagem idCliente={idCliente} />
                        </div>
                        <div className="block lg:hidden col-span-full">
                            <ConsultaClienteListagem idCliente={idCliente} quantidadeResultados={3} />
                        </div>
                    </>
                }
            </div>

            <div className="lg:col-span-8 col-span-full grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-3 flex-1">
                <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow sm:rounded-lg">
                            <div className="px-8 py-5 flex flex-row justify-between">
                                <div>
                                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                        Dados Pessoais
                                    </h2>

                                </div>
                            </div>

                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-6 gap-x-4 gap-y-6 sm:grid-cols-12">
                                    <>
                                        {idCliente &&
                                            <div className="col-span-full items-end flex flex-1">
                                                <LayoutSwitch
                                                    className="py-0 flex-1"
                                                    key="switchAtivo"
                                                    nome="Ativo"
                                                    disabled={salvandoCadastro}
                                                    descricao="Cliente ativo"
                                                    valor={form.ativo}
                                                    setValor={handleChangeAtivo} />
                                            </div>
                                        }
                                        <div className="col-span-6">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Razão Social<Obrigatiorio />
                                            </label>
                                            <input
                                                name="razaoSocial"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.razaoSocial}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="col-span-6">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Nome Fantasia<Obrigatiorio />
                                            </label>
                                            <input
                                                name="nomeFantasia"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.nomeFantasia}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-3 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Tipo Pessoa<Obrigatiorio />
                                            </label>
                                            <Select
                                                name="tipoPessoa"
                                                defaultValue={tipoPessoa}
                                                onChange={value => {
                                                    setTipoPessoa(value)
                                                    let formAtualizado = form
                                                    formAtualizado["tipoPessoa"] = value
                                                    setForm(formAtualizado)
                                                }}
                                                options={opcoesTipoPessoa}
                                                placeholder="Selecione"
                                                noOptionsMessage={() => "Nenhum tipo disponível"}
                                                className="mt-1 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>

                                        <div className="sm:col-span-3 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                {tipoPessoa.value === "PF" ? "CPF" : "CNPJ"}<Obrigatiorio />
                                            </label>
                                            <InputMask
                                                name="documento"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.documento}
                                                onChange={handleInputChange}
                                                mask={tipoPessoa.value === "PF" ? "999.999.999-99" : "99.999.999/9999-99"}
                                                maskChar=""
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="col-span-6">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Email
                                            </label>
                                            <input
                                                name="email"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.email}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-3 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Celular
                                            </label>
                                            <InputMask
                                                name="celular"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.celular}
                                                onChange={handleInputChange}
                                                mask="(99) 99999-9999"
                                                maskChar=""
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-3 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Telefone
                                            </label>
                                            <InputMask
                                                name="telefone"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.telefone}
                                                onChange={handleInputChange}
                                                mask="(99) 99999-9999"
                                                maskChar=""
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>

                                        <div className="col-span-6">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Nome Completo
                                            </label>
                                            <input
                                                name="nomeCompleto"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.nomeCompleto}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </>
                                </dl>
                            </div>

                        </div>
                    </section>

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow sm:rounded-lg">
                            <div className="px-8 py-5 flex flex-row justify-between">
                                <div>
                                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                        Endereço
                                    </h2>

                                </div>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-6 gap-x-4 gap-y-6 sm:grid-cols-12">
                                    <>
                                        <div className="sm:col-span-3 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                CEP<Obrigatiorio />
                                            </label>
                                            <InputMask
                                                name="cep"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.cep}
                                                onChange={handleInputChange}
                                                mask="99999-999"
                                                maskChar=""
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>

                                        <div className="sm:col-span-3 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                UF<Obrigatiorio />
                                            </label>
                                            <Select
                                                name="uf"
                                                value={form.uf}
                                                onChange={value => handleInputChange(value, 'uf')}
                                                onInputChange={value => setPesquisaUf(value)}
                                                options={opcoesUf}
                                                placeholder="Selecione"
                                                noOptionsMessage={() => "Nenhuma UF disponível"}
                                                className="mt-1 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-3 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Município<Obrigatiorio />
                                            </label>
                                            <Select
                                                name="municipio"
                                                value={form.municipio}
                                                onChange={value => handleInputChange(value, 'municipio')}
                                                onInputChange={value => setPesquisaMunicipio(value)}
                                                options={opcoesMunicipio}
                                                placeholder="Selecione"
                                                noOptionsMessage={() => "Nenhum Município disponível"}
                                                className="mt-1 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-3 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Bairro
                                            </label>
                                            <input
                                                name="bairro"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.bairro}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-4 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Logradouro
                                            </label>
                                            <input
                                                name="logradouro"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.logradouro}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-2 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Número
                                            </label>
                                            <input
                                                name="numero"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.numero}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="col-span-6">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Complemento
                                            </label>
                                            <input
                                                name="complemento"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.complemento}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </>
                                </dl>
                            </div>
                        </div>
                    </section>

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow sm:rounded-lg">
                            <div className="px-8 py-5 flex flex-row justify-between">
                                <div>
                                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                        Plano
                                    </h2>

                                </div>
                            </div>

                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-6 gap-x-4 gap-y-6 sm:grid-cols-12">
                                    <>
                                        <div className="sm:col-span-4 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Plano<Obrigatiorio />
                                            </label>
                                            <Select
                                                name="plano"
                                                defaultValue={form?.plano ? form.plano : { value: '', label: "Selecione" }}
                                                onChange={value => handleInputChange(value, 'plano')}
                                                onInputChange={value => setPesquisaPlano(value)}
                                                options={opcoesPlano}
                                                placeholder="Selecione"
                                                noOptionsMessage={() => "Nenhum plano disponível"}
                                                className="mt-1 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-4 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Revenda<Obrigatiorio />
                                            </label>
                                            <Select
                                                name="revenda"
                                                defaultValue={form?.revenda ? form.revenda : { value: '', label: "Selecione" }}
                                                onChange={value => handleInputChange(value, 'revenda')}
                                                onInputChange={value => setPesquisaRevenda(value)}
                                                options={opcoesRevenda}
                                                placeholder="Selecione"
                                                noOptionsMessage={() => "Nenhuma revenda disponível"}
                                                className="mt-1 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>

                                        <div className="sm:col-span-4 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Contador
                                            </label>
                                            <Select
                                                name="contador"
                                                defaultValue={form?.contador ? form.contador : { value: '', label: "Selecione" }}
                                                onChange={value => handleInputChange(value, 'contador')}
                                                onInputChange={value => setPesquisaContador(value)}
                                                options={opcoesContador}
                                                placeholder="Selecione"
                                                noOptionsMessage={() => "Nenhum contador disponível"}
                                                className="mt-1 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-3 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Valor
                                            </label>
                                            <CurrencyInput
                                                name="valor"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.valor}
                                                decimalsLimit={2}
                                                allowNegativeValue={false}
                                                prefix="R$"
                                                onValueChange={(value, name) => {
                                                    const valorDecimal = parseFloat(value.replace(',', '.'));
                                                    const formAtualizado = form;
                                                    formAtualizado[name] = valorDecimal
                                                    setForm(formAtualizado)
                                                }}
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-3 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Qtd. Licença
                                            </label>
                                            <input
                                                name="qtdLicenca"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.quantidadeLicenca}
                                                onChange={handleInputChange}
                                                type="number"
                                                min="0"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-3 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Data Aplicação<Obrigatiorio />
                                            </label>
                                            <input
                                                name="dataAplicacao"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.dataAplicacao}
                                                onChange={handleInputChange}
                                                type="date"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-3 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Data Validade<Obrigatiorio />
                                            </label>
                                            <input
                                                name="dataValidade"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.dataValidade}
                                                onChange={handleInputChange}
                                                type="date"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Link Boleto
                                            </label>
                                            <input
                                                name="linkBoleto"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.linkBoleto}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </>
                                </dl>
                            </div>

                        </div>
                    </section>

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow sm:rounded-lg">
                            <div className="px-8 py-5 flex flex-row justify-between">
                                <div>
                                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                        Outros
                                    </h2>

                                </div>
                            </div>

                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-6 gap-x-4 gap-y-6 sm:grid-cols-12">
                                    <>

                                        <div className="col-span-full items-end flex flex-1">
                                            <LayoutSwitch
                                                className="py-0 flex-1"
                                                key="switchAtivo"
                                                nome="mdeAtivo"
                                                disabled={salvandoCadastro}
                                                descricao="MDE Ativo"
                                                valor={form.mdeAtivo}
                                                setValor={handleChangeMdeAtivo} />
                                        </div>
                                        <div className="sm:col-span-6 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Tipo de Certificado Digital
                                            </label>
                                            <Select
                                                name="tipoCertificadoDigital"
                                                defaultValue={form?.tipoCertificadoDigital ? form.tipoCertificadoDigital : { value: '', label: "Selecione" }}
                                                onChange={value => handleInputChange(value, 'tipoCertificadoDigital')}
                                                options={opcoesTipoCertificadoDigital}
                                                placeholder="Selecione"
                                                noOptionsMessage={() => "Nenhum tipo de certificado digital disponível"}
                                                className="mt-1 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-6 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Senha Certificado Digital
                                            </label>
                                            <input
                                                name="senhaCertificadoDigital"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.senhaCertificadoDigital}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Anexo Certificado Digital
                                            </label>
                                            <Dropzone
                                                className="grid grid-cols-1 gap-4 justify-items-center"
                                                anexo={anexo}
                                                preview={true}
                                                texto="Clique ou arraste a Imagem desejada até aqui"
                                                setAnexo={handleChangeAnexoCertificadoDigital} >
                                                <div className="flex items-center space-x-5">
                                                    <div className="flex-shrink-0">
                                                        <div className="relative">
                                                            <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                                                            <img
                                                                className="object-contain h-20 w-20 rounded-full"
                                                                src={anexoUrl || require('../../assets/images/default-image.png')}
                                                                alt=""
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null;
                                                                    currentTarget.src = require('../../assets/images/default-image.png');
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Dropzone>
                                        </div>
                                        <div className="col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Chave AC
                                            </label>
                                            <textarea
                                                rows={5}
                                                name="chaveAC"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.chaveAC}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                            </textarea>
                                        </div>
                                        <div className="sm:col-span-4 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Chave
                                            </label>
                                            <input
                                                name="chave"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.chave}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>


                                        <div className="sm:col-span-4 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Estação Cliente
                                            </label>
                                            <input
                                                name="estacaoCliente"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.estacaoCliente}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-4 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                String Conexão Servidor Autenticação
                                            </label>
                                            <input
                                                name="stringConexaoServidorAutenticacao"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.stringConexaoServidorAutenticacao}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-4 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                String Conexão Sistema Web
                                            </label>
                                            <input
                                                name="stringConexaoSistemaWeb"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.stringConexaoSistemaWeb}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-4 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Web Api Forca De Vendas
                                            </label>
                                            <input
                                                name="webApiForcaDeVendas"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.webApiForcaDeVendas}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="sm:col-span-4 col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Web Api Comanda
                                            </label>
                                            <input
                                                name="webApiComanda"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.webApiComanda}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="col-span-full">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Dados Adicionais
                                            </label>
                                            <input
                                                name="dadosAdicionais"
                                                disabled={salvandoCadastro}
                                                defaultValue={form?.dadosAdicionais}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </>
                                </dl>
                            </div>
                        </div>
                    </section>

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow sm:rounded-lg">
                            {!idCliente ?
                                <div className="m-6 py-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                                    <button
                                        type="button"
                                        onClick={() => navigate(`/cliente`)}
                                        className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        type="button"
                                        disabled={salvandoCadastro}
                                        onClick={salvarDebounce}
                                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500"
                                    >
                                        Salvar Cliente
                                    </button>
                                </div>
                                :
                                <div className="m-6 py-6 flex flex-row flex-wrap justify-between space-y-4 items-center">
                                    <div className="text-sm text-gray-600 grid sm:grid-cols-2 grid-cols-1 gap-4">
                                        <div>
                                            <span className="text-xs text-gray-400">Cadastro</span>
                                            <p>{form.usuarioCadastro}, {new Date(form.dataCadastro).toLocaleDateString()} - {new Date(form.dataCadastro).toLocaleTimeString()}</p>
                                        </div>
                                        <div>
                                            <span className="text-xs text-gray-400">Última alteração</span>
                                            <p>{form.usuarioUltimaAlteracao}, {new Date(form.dataUltimaAlteracao).toLocaleDateString()} - {new Date(form.dataUltimaAlteracao).toLocaleTimeString()}</p>
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={salvarDebounce}
                                        disabled={salvandoCadastro}
                                        className={`${(salvandoCadastro) ? "cursor-not-allowed" : "cursor-pointer"} inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500`}
                                    >
                                        {salvandoCadastro && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>}
                                        Salvar Alterações
                                    </button>
                                </div>
                            }
                        </div>
                    </section>


                </div>
            </div>
        </main>
    )
}
