import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

import {
    CogIcon,
} from '@heroicons/react/outline'

import FormConfiguracaoGeral from "../../components/FormConfiguracaoGeral";
import verificaAcesso from '../../utils/verificaAcesso';
import AutorizacaoDropbox from "../../components/AutorizacaoDropBox";
import { BadgeCheckIcon } from "@heroicons/react/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Configuracoes() {
    let navigate = useNavigate();
    const location = useLocation();

    //acessos
    const desc_funcionalidade = "CONFIGURACAO";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')

    const [subNavigation, setSubNavigation] = useState([])

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso(location.pathname.split("/")[1],
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                const itensConfiguracao = [
                    { key: 1, name: 'Geral', icon: CogIcon, current: true, component: <FormConfiguracaoGeral /> },
                    { key: 2, name: 'Autorização Dropbox', icon: BadgeCheckIcon, current: false, component: <AutorizacaoDropbox /> },
                ]
                setSubNavigation(itensConfiguracao)
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    function setActiveNavigation(indice) {
        const subNavigationDepois = subNavigation.map((item, i) => {
            if (indice === i) item.current = true
            else item.current = false

            return item
        })

        setSubNavigation([...subNavigationDepois])
    }

    return (
        <div className="h-full">
            <main className="py-8 px-8">
                <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                    <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
                        <nav className="space-y-1">
                            {subNavigation.map((item, indice) => (
                                <button
                                    key={item.name}
                                    onClick={() => {
                                        setActiveNavigation(indice)
                                    }}
                                    className={classNames(
                                        item.current
                                            ? 'bg-gray-50 text-blue-800 hover:bg-white'
                                            : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                                        'group w-full rounded-md px-3 py-2 flex items-center text-sm font-medium'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    <item.icon
                                        className={classNames(
                                            item.current ? 'text-blue-700' : 'text-gray-400 group-hover:text-gray-500',
                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span className="truncate">{item.name}</span>
                                </button>
                            ))}
                        </nav>
                    </aside>

                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10">
                        {subNavigation.map((item) => {
                            if (item.current) return <div key={item.key}>{item.component}</div>
                            else return null
                        })}
                    </div>
                </div>
            </main>
        </div>
    )
}
