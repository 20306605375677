import React, { useState } from 'react'
import { login } from '../../services/auth'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

export default function Login() {
  const navigate = useNavigate()

  const [usuario, setUsuario] = useState('')
  const [senha, setSenha] = useState('')
  const [loading, setLoading] = useState(false)
  const [erroLogin, setErroLogin] = useState(false)
  const [mostrarSenha, setMostrarSenha] = useState(false)

  async function handleLogin() {
    setErroLogin(false)
    setLoading(true)
    const toastLogin = toast.loading("Verificando usuário...")

    const usuarioLogin = {
      email: usuario,
      senha: senha,
    }

    const response = await login(usuarioLogin)
    if (response.sucesso) {
      localStorage.setItem('@admin_Token', response.data.token)
      navigate('/')
      toast.update(toastLogin, {
        render: "Login realizado com sucesso",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true
      });
    }
    else {
      setErroLogin(true)
      toast.update(toastLogin, {
        render: response.mensagem,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeButton: true
      });
    }
    setLoading(false)
  }

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-32 w-full object-cover"
                src="/logo_completa.png"
                alt="Workflow"
              />
              {/* <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
              <p className="mt-2 text-sm text-gray-600">
                Or{' '}
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  start your 14-day free trial
                </a>
              </p> */}
            </div>

            <div className="mt-8">
              <form action="#" method="POST" className="space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className={`${erroLogin ? 'border-red-600' : ''} appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}

                      onKeyDown={handleEnter}
                      disabled={loading}
                      placeholder="E-mail"
                      value={usuario}
                      onChange={e => setUsuario(e.target.value)}
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Senha
                  </label>
                  <div className="w-full flex mt-1">
                    <input
                      onKeyDown={handleEnter}
                      disabled={loading}
                      type={mostrarSenha ? 'text' : 'password'}
                      value={senha}
                      onChange={e => setSenha(e.target.value)}
                      id="password"
                      name="password"
                      placeholder='Senha'
                      autoComplete="current-password"
                      required
                      className={`${erroLogin ? 'border-red-600' : ''} appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                    />
                    <div className='relative cursor-pointer ' onClick={() => setMostrarSenha(!mostrarSenha)}>
                      {mostrarSenha ? <EyeIcon className='absolute right-0 top-1 pr-4 w-8 h-8 text-gray-400' /> :
                        <EyeOffIcon className='absolute right-0 top-1 pr-4 w-8 h-8 text-gray-400' />}
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#243f6e] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

                    onKeyDown={handleLogin}
                    disabled={loading}
                    onClick={handleLogin}
                  >
                    Entrar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1 bg-[#0d1827]">
          <img
            className="absolute inset-0 h-full w-1/2 mx-auto object-contain filter grayscale contrast-200 invert"
            src="/logo_completa.png"
            alt=""
          />
        </div>
      </div>
    </>
  )
}
