export const formatarTelefone = telefone => {
    var r = telefone.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
        r = r.replace(/^(\d*)/, "($1");
    }
    return r;
}

export const formatarCpf = cpf => {
    var r = cpf.replace(/\D/g, "");
    r = r.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, "$1.$2.$3-$4");

    return r;
}

export const formatarCep = cep => {
    var r = cep.replace(/\D/g, "");
    r = r.replace(/^(\d{5})(\d{3}).*/, "$1-$2");

    return r;
}

export const formatarData = data => {
    return new Date(data).toLocaleDateString()
}

export const formatarDataHora = data => {
    return `${new Date(data).toLocaleDateString()} - ${ new Date(data).toLocaleTimeString() }`
}