import instance from '../configAxios';

export const getUsuarioById = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/usuarios/${id}`)
    return { sucesso: response.status, data: response.data }
  } catch (error) {
    return { sucesso: false, mensagem: error.response.data };
  }
}

export const getUsuarioLogado = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/usuarios/logado`)
    return { sucesso: response.status, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListUsuario = async (pageSize, currentPage) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/usuarios/?pageSize=${pageSize}&currentPage=${currentPage}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const postUsuario = async novoUsuario => {
  const axios = await instance();

  try {
    const response = await axios.post(`/usuarios`, novoUsuario)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const putUsuario = async (idUsuario, usuarioEditado) => {
  const axios = await instance();

  try {
    const response = await axios.put(`/usuarios/${idUsuario}`, usuarioEditado)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const alterarSenhaUsuario = async (id, novaSenha) => {
  const axios = await instance();

  try {
    const response = await axios.patch(`/usuarios/alterarSenha/${id}`, novaSenha)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const deletarUsuario = async idUsuario => {
  const axios = await instance();

  try {
    const response = await axios.delete(`/usuarios/${idUsuario}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}