import instance from '../configAxios';

export const getRevendaById = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/revenda/${id}`)
    return { sucesso: response.status, data: response.data }
  } catch (error) {
    return { sucesso: false, mensagem: error.response.data };
  }
}

export const getRevendaLogado = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/revenda/logado`)
    return { sucesso: response.status, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListRevenda = async (filtros) => {
  const axios = await instance();

  try {
    const response = await axios.post(`/revenda/listagem`, filtros)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const postRevenda = async novoRevenda => {
  const axios = await instance();

  try {
    const response = await axios.post(`/revenda`, novoRevenda)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const putRevenda = async (idRevenda, revendaEditado) => {
  const axios = await instance();

  try {
    const response = await axios.put(`/revenda/${idRevenda}`, revendaEditado)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const alterarSenhaRevenda = async (id, novaSenha) => {
  const axios = await instance();

  try {
    const response = await axios.patch(`/revenda/alterarSenha/${id}`, novaSenha)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const deletarRevenda = async idRevenda => {
  const axios = await instance();

  try {
    const response = await axios.delete(`/revenda/${idRevenda}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getSelectRevenda = async (pesquisa) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/revenda/select?pesquisa=${pesquisa}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}