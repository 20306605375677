import instance from '../configAxios';

export const getArquivoClienteById = async (id) => {
    const axios = await instance();

    try {
        const response = await axios.get(`/ArquivoCliente/${id}`)
        return { sucesso: response.status, data: response.data }
    } catch (error) {
        return { sucesso: false, mensagem: error.response.data };
    }
}

export const getListArquivoCliente = async (filtros) => {
    const axios = await instance();

    try {
        const response = await axios.post(`/ArquivoCliente/listagem`, filtros)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const updateArquivoCliente = async (id) => {
    const axios = await instance();

    try {
        const response = await axios.put(`/ArquivoCliente/${id}`)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const getListSelectTipoDocumentoArquivoCLiente = async () => {
    const axios = await instance();

    try {
        const response = await axios.get(`/ArquivoCLiente/TipoDocumento/select`)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const getListSelectTipoMovimentoArquivoCLiente = async () => {
    const axios = await instance();

    try {
        const response = await axios.get(`/ArquivoCLiente/TipoMovimento/select`)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const baixarTodosArquivosCLiente = async (filtros) => {
    const axios = await instance();

    try {
        const response = await axios.post(`/ArquivoCliente/baixarTodos`, filtros)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const getResumoUploadArquivos = async (filtro) => {
    const axios = await instance();

    try {
        const response = await axios.post(`/ArquivoCliente/resumo`, filtro)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}