import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ReactECharts from 'echarts-for-react';
import { getResumoUploadArquivos } from '../../services/arquivoCliente';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function ResumoUploadArquivos(props) {
  const {
    inicioPeriodo,
    fimPeriodo,
  } = props

  const [uploadArquivosPeriodo, setUploadArquivosPeriodo] = useState(null)

  async function carregaUploadArquivosPeriodo() {
    const periodo = {
      inicioPeriodo: inicioPeriodo,
      fimPeriodo: fimPeriodo,
    }
    const response = await getResumoUploadArquivos(periodo);

    if (response.sucesso) {
      const labels = response.data.map(sessao => sessao.periodo.split("T")[0].split("-").reverse().join("/"));
      const quantidade = response.data.map(sessao => sessao.quantidade)

      const medias = quantidade.map(q => Math.floor(quantidade.reduce((a, b) => a + b, 0) / quantidade.length))

      const options = {
        grid: { top: 8, right: 40, bottom: 24, left: 40 },
        xAxis: {
          type: 'category',
          data: labels,
        },
        yAxis: {
          type: 'value',
        },
        
        series: [
          {
            data: quantidade,
            type: 'bar',
            name: 'Upload de Arquivos',
            color: '#91cc75',
            smooth: false,
          }, {
            data: medias,
            type: 'line',
            name: 'Média',
            color: '#91cc7588',
            smooth: true,
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
      };

      setUploadArquivosPeriodo(options)
    }
  }

  useEffect(() => {
    if (!inicioPeriodo && !fimPeriodo) return;

    carregaUploadArquivosPeriodo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inicioPeriodo, fimPeriodo]);

  return (
    <div className='w-full'>
      {uploadArquivosPeriodo && <ReactECharts option={uploadArquivosPeriodo} />}
    </div>
  )
}
