import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import Select from 'react-select';
import LoadingPage from "../../components/LoadingPage";
import { getNcmById, postNcm, putNcm } from "../../services/ncm";
import { getListUf } from "../../services/endereco";
import useDebounce from "../../hooks/useDebounce";

export default function NcmForm() {
  let navigate = useNavigate();
  const location = useLocation();

  let idNcm = location.state ? location.state.idNcm : null;

  const salvarDebounce = useDebounce(handleSalvarNcm, 500)

  //acessos
  const desc_funcionalidade = "NCM";
  const [acessoVerificado, setAcessoVerificado] = useState(false);
  const [listar, setListar] = useState('')
  const [editar, setEditar] = useState('')

  const [loading, setLoading] = useState(true);
  const [salvandoCadastro, setSalvandoCadastro] = useState(false);

  const [codigo, setCodigo] = useState('');
  const [ex, setEx] = useState('');
  const [tipo, setTipo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [nacionalfederal, setNacionalFederal] = useState('');
  const [importadosFederal, setImportadosFederal] = useState('');
  const [estadual, setEstadual] = useState('');
  const [municipal, setMunicipal] = useState('');
  const [vigenciaInicio, setVigenciaInicio] = useState('');
  const [vigenciaFim, setVigenciaFim] = useState('');
  const [chave, setChave] = useState('');
  const [versao, setVersao] = useState('');
  const [fonte, setFonte] = useState('');
  const [uf, setUf] = useState('');
  const [usuarioCadastro, setUsuarioCadastro] = useState(null)
  const [dataCadastro, setDataCadastro] = useState(null)
  const [usuarioUltimaAlteracao, setUsuarioUltimaAlteracao] = useState(null)
  const [dataUltimaAlteracao, setDataUltimaAlteracao] = useState(null)

  const [opcoesUf, setOpcoesUf] = useState([]);
  const ufDebounce = useDebounce(carregaOpcoesUf, 500)
  const [pesquisaUf, setPesquisaUf] = useState("")

  useEffect(() => {
    if (!acessoVerificado) {
      verificaAcesso(location.pathname.split("/")[1],
        { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
        { acao: setEditar, nome: desc_funcionalidade + "_EDITAR" },
      )
      setAcessoVerificado(true)
    }
  }, [acessoVerificado, location.pathname])

  useEffect(() => {
    if (acessoVerificado) {
      if (listar) {
        if (idNcm) {
          carregaNcm()
        } else {
          setLoading(false)
        }
        carregaOpcoesUf()
      } else {
        toast.error(`Sem acesso a Visualização de ${desc_funcionalidade}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          navigate('/')
        }, 100)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listar])

  async function carregaNcm() {
    setLoading(true)

    const response = await getNcmById(idNcm)

    if (response.sucesso) {
      setCodigo(response.data.codigo)
      setEx(response.data.ex)
      setTipo(response.data.tipo)
      setDescricao(response.data.descricao)
      setNacionalFederal(response.data.nacionalFederal)
      setImportadosFederal(response.data.importadosFederal)
      setEstadual(response.data.estadual)
      setMunicipal(response.data.municipal)
      setVigenciaInicio(response.data.vigenciaInicio.split("T")[0])
      setVigenciaFim(response.data.vigenciaFim.split("T")[0])
      setChave(response.data.chave)
      setVersao(response.data.versao)
      setFonte(response.data.fonte)
      setUf({ value: response.data.ufId, label: response.data.ufDescricao })
      setUsuarioCadastro(response.data.usuarioCadastro)
      setDataCadastro(response.data.dataCadastro)
      setUsuarioUltimaAlteracao(response.data.usuarioUltimaAlteracao)
      setDataUltimaAlteracao(response.data.dataUltimaAlteracao)
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setLoading(false)
  }

  async function carregaOpcoesUf(pesquisa = pesquisaUf) {
    const response = await getListUf(pesquisa)
    if (response.sucesso) {
      const opcoes = response.data.map(uf => {
        return {
          value: uf.id,
          label: uf.descricao
        }
      })
      setOpcoesUf([{ value: '', label: 'Todos' }, ...opcoes])
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  useEffect(() => {
    if (pesquisaUf) ufDebounce()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pesquisaUf])

  function handleSelectUf(ufSelecionadda) {
    setUf(ufSelecionadda)
  }

  async function handleSalvarNcm() {
    if (fonte) return;
    setSalvandoCadastro(true)

    let response = { sucesso: false, mensagem: "Erro ao salvar NCM" }

    if (idNcm) {
      const ncmEditado = {
        id: idNcm,
        codigo: codigo,
        ex: ex,
        tipo: tipo,
        descricao: descricao,
        nacionalfederal: nacionalfederal,
        importadosfederal: importadosFederal,
        estadual: estadual,
        municipal: municipal,
        vigenciaInicio: vigenciaInicio,
        vigenciaFim: vigenciaFim,
        chave: chave,
        versao: versao,
        ufId: uf.value,
        fonte: fonte,
      }

      response = await putNcm(idNcm, ncmEditado)

    } else {
      const novoNcm = {
        codigo: codigo,
        ex: ex,
        tipo: tipo,
        descricao: descricao,
        nacionalfederal: nacionalfederal,
        importadosfederal: importadosFederal,
        estadual: estadual,
        municipal: municipal,
        vigenciaInicio: vigenciaInicio,
        vigenciaFim: vigenciaFim,
        chave: chave,
        versao: versao,
        ufId: uf.value,
        fonte: fonte,
      }

      response = await postNcm(novoNcm)
    }

    if (response.sucesso) {
      toast.success('NCM Salva com sucesso!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate(`/ncm`)
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setSalvandoCadastro(false)
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <main className="py-8 lg:flex flex-row">
      <div className="grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-3 flex-1">
        <div className="space-y-6 lg:col-start-1 lg:col-span-3">
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-8 py-5 flex flex-row justify-between">
                <div>
                  <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                    Dados NCM
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Cadastro de NCM</p>
                </div>
              </div>

              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-6 gap-x-4 gap-y-6 sm:grid-cols-12">
                  <>
                    <div className="col-span-6">
                      <label className="block text-sm font-medium text-gray-700">
                        Código
                      </label>
                      <input
                        disabled={salvandoCadastro}
                        defaultValue={codigo}
                        onChange={(e) => setCodigo(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6">
                      <label className="block text-sm font-medium text-gray-700">
                        Descrição
                      </label>
                      <input
                        disabled={salvandoCadastro}
                        defaultValue={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6">
                      <label className="block text-sm font-medium text-gray-700">
                        UF
                      </label>
                      <Select
                        name="uf"
                        isDisabled={salvandoCadastro}
                        value={uf}
                        onChange={handleSelectUf}
                        onInputChange={value => setPesquisaUf(value)}
                        options={opcoesUf}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhuma UF disponível"}
                        className="mt-1 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="sm:col-span-3 col-span-full">
                      <label className="block text-sm font-medium text-gray-700">
                        Ex.
                      </label>
                      <input
                        disabled={salvandoCadastro}
                        defaultValue={ex}
                        onChange={(e) => setEx(e.target.value)}
                        type="email"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="sm:col-span-3 col-span-full">
                      <label className="block text-sm font-medium text-gray-700">
                        Tipo
                      </label>
                      <input
                        disabled={salvandoCadastro}
                        defaultValue={tipo}
                        onChange={(e) => setTipo(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <span className="col-span-full border-b"></span>
                    <div className="sm:col-span-3 col-span-full">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 truncate flex">
                        <p className="truncate mr-1">Nacional Federal</p>
                      </label>
                      <input
                        disabled={salvandoCadastro}
                        defaultValue={nacionalfederal}
                        onChange={(e) => setNacionalFederal(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="sm:col-span-3 col-span-full">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 truncate flex">
                        <p className="truncate mr-1">Importados Federal</p>
                      </label>
                      <input
                        disabled={salvandoCadastro}
                        defaultValue={importadosFederal}
                        onChange={(e) => setImportadosFederal(e.target.value)}
                        type="email"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="sm:col-span-3 col-span-full">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 truncate">
                        Estadual
                      </label>
                      <input
                        disabled={salvandoCadastro}
                        defaultValue={estadual}
                        onChange={(e) => setEstadual(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="sm:col-span-3 col-span-full">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 truncate">
                        Municipal
                      </label>
                      <input
                        disabled={salvandoCadastro}
                        defaultValue={municipal}
                        onChange={(e) => setMunicipal(e.target.value)}
                        type="email"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <span className="col-span-full border-b"></span>
                    <div className="col-span-6">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 truncate flex">
                        <p className="truncate mr-1">Vigência Início</p>
                      </label>
                      <input
                        disabled={salvandoCadastro}
                        defaultValue={vigenciaInicio}
                        onChange={(e) => setVigenciaInicio(e.target.value)}
                        type="date"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 truncate flex">
                        <p className="truncate mr-1">Vigência Fim</p>
                      </label>
                      <input
                        disabled={salvandoCadastro}
                        defaultValue={vigenciaFim}
                        onChange={(e) => setVigenciaFim(e.target.value)}
                        type="date"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <span className="col-span-full border-b"></span>
                    <div className="col-span-6">
                      <label className="block text-sm font-medium text-gray-700">
                        Chave
                      </label>
                      <input
                        disabled={salvandoCadastro}
                        defaultValue={chave}
                        onChange={(e) => setChave(e.target.value)}
                        type="email"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6">
                      <label className="block text-sm font-medium text-gray-700">
                        Versão
                      </label>
                      <input
                        disabled={salvandoCadastro}
                        defaultValue={versao}
                        onChange={(e) => setVersao(e.target.value)}
                        type="email"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <span className="col-span-full border-b"></span>
                    <div className="col-span-full">
                      <label className="block text-sm font-medium text-gray-700">
                        Fonte
                      </label>
                      <input
                        disabled={true}
                        defaultValue={fonte}
                        type="text"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </>
                </dl>
              </div>

              {idNcm && <div className="m-6 pb-6 text-sm text-gray-600 grid sm:grid-cols-2 grid-cols-1 gap-4">
                <div>
                  <span className="text-xs text-gray-400">Cadastro</span>
                  <p>{usuarioCadastro}, {new Date(dataCadastro).toLocaleDateString()} - {new Date(dataCadastro).toLocaleTimeString()}</p>
                </div>
                <div className="text-sm text-gray-600">
                  <span className="text-xs text-gray-400">Última alteração</span>
                  <p>{usuarioUltimaAlteracao}, {new Date(dataUltimaAlteracao).toLocaleDateString()} - {new Date(dataUltimaAlteracao).toLocaleTimeString()}</p>
                </div>
              </div>}
              {idNcm && editar && !fonte && <div className="m-6 pb-6 flex flex-row flex-wrap justify-between space-y-4 items-center">
                <button
                  type="button"
                  onClick={salvarDebounce}
                  disabled={salvandoCadastro}
                  className={`${(salvandoCadastro) ? "cursor-not-allowed" : "cursor-pointer"} inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500`}
                >
                  {salvandoCadastro && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>}
                  Salvar Alterações
                </button>
              </div>}
              {!idNcm && !fonte && <div className="m-6 pb-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <button
                  type="button"
                  onClick={() => navigate(`/ncm`)}
                  className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  onClick={salvarDebounce}
                  disabled={salvandoCadastro}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500"
                >
                  Salvar NCM
                </button>
              </div>}
            </div>
          </section>
        </div>
      </div>
    </main>
  )
}
