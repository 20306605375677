import React, { useState, useEffect } from 'react'
import { getListClienteBackup, } from '../../services/clienteBackup'
import { useNavigate } from "react-router-dom";
import LayoutPaginacaoTabela from "../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import { SearchIcon } from '@heroicons/react/outline'
import useDebounce from '../../hooks/useDebounce';
import { formatarDataHora } from "../../utils/formartar";
import { getSelectCliente } from '../../services/cliente'
import Select from 'react-select';
import { DownloadIcon } from '@heroicons/react/solid';
import Loading from '../../components/Loading';

export default function ClienteBackup(props) {
    let navigate = useNavigate();
    //acessos
    const desc_funcionalidade = "CLIENTE_BACKUP";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')

    const [listaClienteBackup, setListaClienteBackup] = useState([])

    const [baixandoArquivo, setBaixandoArquivo] = useState(false)
    const [arquivoSelecionado, setArquivoSelecionado] = useState(null)

    const [loading, setLoading] = useState(true)

    //Filtros
    const [filtroCliente, setFiltroCliente] = useState({ value: null, label: "Todas" })
    const [opcoesCliente, setOpcoesCliente] = useState([]);
    const clienteDebounce = useDebounce(carregaOpcoesCliente, 500)
    const [pesquisaCliente, setPesquisaCliente] = useState("")

    const hoje = new Date().toLocaleDateString().split("T")[0].split("/").reverse().join("-");
    const mesPassado = new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString().split("T")[0].split("/").reverse().join("-");

    const [inicioPeriodo, setInicioPeriodo] = useState(mesPassado);
    const [fimPeriodo, setFimPeriodo] = useState(hoje);

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroCliente, inicioPeriodo, fimPeriodo])

    async function carregaOpcoesCliente(pesquisa = pesquisaCliente) {
        const response = await getSelectCliente(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(cliente => {
                return {
                    value: cliente.id,
                    label: cliente.descricao
                }
            })
            setOpcoesCliente([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = props.quantidadeResultados || 5

    const ClienteLinha = ({ cliente }) => {
        return (
            <div>
                <div className="text-sm font-medium text-gray-800 truncate flex capitalize">
                    <p className='truncate mr-1'>{cliente.razaoSocial.toLowerCase()}</p>
                </div>
                <div className="text-sm text-gray-500 truncate capitalize">
                    <p className='truncate mr-1'>{cliente.nomeFantasia.toLowerCase()}</p>
                </div>
            </div>
        )
    }

    async function handleDownload(arquivo) {
        if (baixandoArquivo) {
            toast.info(`Download em andamento`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        setBaixandoArquivo(true)
        setArquivoSelecionado(arquivo)

        try {
            window.open(arquivo.dropboxLinkDownload, "_blank");

            toast.success(`Arquivo salvo com sucesso!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBaixandoArquivo(false)

            setTimeout(() => {
                setArquivoSelecionado(null)
            }, 5000);
        } catch (error) {
            toast.error(`Erro ao baixar arquivo`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBaixandoArquivo(false)
            setArquivoSelecionado(null)
        }
    }

    const DadosClienteBackupLinha = ({ dadosLinha }) => {
        const {
            arquivoBackup,
            dataBackup,
        } = dadosLinha

        return (
            <>
                <button disabled={baixandoArquivo} onClick={() => handleDownload(arquivoBackup)} className='flex flex-row items-center gap-4'>
                    {baixandoArquivo && arquivoSelecionado?.id === arquivoBackup?.id ?
                        <Loading fill={'green'} />
                        :
                        <DownloadIcon className='h-6 text-green-600' />
                    }
                    <p className='font-semibold text-gray-600'>{arquivoBackup.nomeCompleto}</p>
                </button>

                <div className='flex flex-row items-center gap-4 text-sm font-medium text-gray-800 truncate'>
                    <p className='truncate mr-1'>Data Backup: {formatarDataHora(dataBackup)}</p>
                </div>
            </>
        )
    }

    const LinhaTabela = ({ dadosLinha }) => {
        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-2 sm:px-6">
                    {props.idCliente ?
                        <div className="grid xl:grid-cols-2 grid-cols-1 gap-2">
                            <DadosClienteBackupLinha dadosLinha={dadosLinha} />
                        </div> :
                        <div className="grid xl:grid-cols-3 grid-cols-1 gap-4">
                            <ClienteLinha cliente={dadosLinha.cliente} />
                            <DadosClienteBackupLinha dadosLinha={dadosLinha} />
                        </div>
                    }
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso("cliente-backup", //location.pathname.split("/")[1]
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaClienteBackup(registrosPorPagina, paginaAtual)
                carregaOpcoesCliente()
            }
            else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaClienteBackup = async (pageSize = registrosPorPagina, currentPage = 0) => {
        setLoading(true);

        const filtros = {
            pageSize,
            currentPage,
            ClienteBackupId: props.ClienteBackupId,
            clienteId: props.idCliente || filtroCliente.value,
            fimPeriodo: fimPeriodo,
            inicioPeriodo: inicioPeriodo,
        }
        if (props.chaveNotaFiscal) filtros.chaveNotaFiscal = props.chaveNotaFiscal

        const response = await getListClienteBackup(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.currentPage)
            setTotalRegistros(response.data.totalRegisters)
            setTotalPaginas(response.data.totalPages)

            setListaClienteBackup(response.data.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    useEffect(() => {
        if (pesquisaCliente) clienteDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaCliente])

    function handleSelectCliente(clienteSelecionado) {
        setFiltroCliente(clienteSelecionado)
    }

    const filtroDebounce = useDebounce(carregaClienteBackup, 500)

    const renderLinhasTabela = () => {
        return listaClienteBackup.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <>
                <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8'>
                        <div>
                            <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                Cliente
                            </label>
                            <Select
                                isDisabled={loading}
                                name="cliente"
                                value={filtroCliente}
                                onChange={handleSelectCliente}
                                onInputChange={value => setPesquisaCliente(value)}
                                options={opcoesCliente}
                                placeholder="Selecione"
                                noOptionsMessage={() => "Nenhuma Cliente disponível"}
                                className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                Início Perídodo
                            </label>
                            <input
                                defaultValue={inicioPeriodo}
                                onChange={e => {
                                    setInicioPeriodo(e.target.value)
                                }}
                                type="date"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                Fim Perídodo
                            </label>
                            <input
                                defaultValue={fimPeriodo}
                                onChange={e => {
                                    setFimPeriodo(e.target.value)
                                }}
                                type="date"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                        </div>
                    </div>

                    <div className="bg-white sm:rounded-lg sm:shadow my-8">
                        <div className="bg-white sm:rounded-t-lg px-4 py-5 border-b border-gray-200 sm:px-6">
                            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                <div className="ml-4 mt-2">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        {props.titulo || "Backup CLiente"}
                                    </h3>
                                </div>
                            </div>
                        </div>

                        {!listaClienteBackup ?
                            <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                                <EmptyPage texto="Nenhuma ClienteBackup Encontrada" Icone={SearchIcon} />
                            </div>
                            :
                            <ul className="divide-y divide-gray-200">
                                {renderLinhasTabela()}
                            </ul>
                        }

                        <LayoutPaginacaoTabela
                            pagina={paginaAtual}
                            totalRegistros={totalRegistros}
                            registrosPorPagina={registrosPorPagina}
                            totalPaginas={totalPaginas}
                            className="rounded-b-lg"
                            onClickPaginaAnterior={() => {
                                setPaginaAtual(paginaAtual - 1)
                                carregaClienteBackup(registrosPorPagina, paginaAtual - 1)
                            }}
                            onClickPaginaPosterior={() => {
                                setPaginaAtual(paginaAtual + 1)
                                carregaClienteBackup(registrosPorPagina, paginaAtual + 1)
                            }}
                            onClickPagina={pagina => {
                                setPaginaAtual(pagina)
                                carregaClienteBackup(registrosPorPagina, pagina)
                            }} />
                    </div>
                </div>
            </>
        )
    }

}