import React, { useState, useEffect } from 'react'
import { ClipboardIcon, ChartBarIcon, ShoppingBagIcon } from '@heroicons/react/solid'
import { getListAnaliseDeUso, getSelectCliente } from '../../services/cliente'
import { useNavigate, useLocation } from "react-router-dom";
import LayoutPaginacaoTabela from "../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import useDebounce from '../../hooks/useDebounce';
import API_URL from '../../utils/api'
import ModalConfirmacao from '../../components/ModalConfirmacao'
import Scrollbars from 'react-custom-scrollbars'
import Select from 'react-select';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AnaliseDeUsoListagem() {
    let navigate = useNavigate();
    const location = useLocation();

    //acessos
    const desc_funcionalidade = "ANALISE_DE_USO";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')

    const [copiarChaveAC, setCopiarChaveAC] = useState(false)
    const [clienteSelecionado, setClienteSelecionado] = useState(null)

    const [listaAnaliseDeUso, setListaAnaliseDeUso] = useState([])

    const [loading, setLoading] = useState(true)

    //Filtros
    const [filtroCliente, setFiltroCliente] = useState({ value: null, label: "Todas" })
    const [opcoesCliente, setOpcoesCliente] = useState([]);
    const clienteDebounce = useDebounce(carregaOpcoesCliente, 500)
    const [pesquisaCliente, setPesquisaCliente] = useState("")

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroCliente])

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = 100

    function handleCopiarChaveAC(dados) {
        setClienteSelecionado(dados)
        setCopiarChaveAC(true)
    }

    async function carregaOpcoesCliente(pesquisa = pesquisaCliente) {
        const response = await getSelectCliente(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(cliente => {
                return {
                    value: cliente.id,
                    label: cliente.descricao
                }
            })
            setOpcoesCliente([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const LinhaTabela = (props) => {
        const {
            dataUltimoAcesso,
            usuario,
            versaoSistema,
            sistema,
            escalaTempo,
            quantidadeTempo,
            ultimoAcesso,
            ultimaVersao
        } = props.dadosLinha

        const documentoMask = (d) => {
            if (usuario.tipoPessoa === "PF" || d.length <= 11) {
                return d.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            } else if (usuario.tipoPessoa === "PJ" || d.length > 11) {
                return d.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/, '$1.$2.$3/$4-$5');
            }
        }

        let corTempo = "text-green-500";

        switch (escalaTempo) {
            case "mes":
                if (quantidadeTempo > 20) corTempo = "text-orange-900 bg-orange-500"
                else if (quantidadeTempo > 15) corTempo = "text-orange-900 bg-orange-400"
                else corTempo = "text-orange-900 bg-orange-200"
                break;
            case "dia":
                if (quantidadeTempo > 18) corTempo = "text-yellow-900 bg-yellow-500"
                else if (quantidadeTempo > 12) corTempo = "text-yellow-900 bg-yellow-400"
                else corTempo = "text-yellow-900 bg-yellow-200"
                break;
            case "hora":
                if (quantidadeTempo > 50) corTempo = "text-blue-900 bg-blue-500"
                else if (quantidadeTempo > 30) corTempo = "text-blue-900 bg-blue-400"
                else corTempo = "text-blue-900 bg-blue-200"
                break;
            case "minuto":
                if (quantidadeTempo > 50) corTempo = "text-green-900 bg-green-500"
                else if (quantidadeTempo > 30) corTempo = "text-green-900 bg-green-400"
                else corTempo = "text-green-900 bg-green-200"
                break;

            default:
                break;
        }

        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-4 sm:px-6">
                    <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
                        <div className="lg:col-span-2 col-span-auto flex flex-row flex-wrap sm:flex-nowrap shrink-0">
                            <img
                                className="h-12 w-12 mb-2 mr-4 rounded-lg object-contain"
                                src={usuario.arquivoLogoId ? `${API_URL}/arquivo/download/${usuario.arquivoLogoId}` : require('../../assets/images/user.png')}
                                alt=""
                            />
                            <div className="grid-cols-1 lg:grid-cols-2 grid flex-1">
                                <div className="text-sm font-medium text-gray-800 flex capitalize">
                                    <p className='break-words mr-1'>{usuario.id} - {usuario.nomeFantasia}</p>
                                </div>
                                <div className="flex text-sm text-gray-500 flex">
                                    <p className='break-words mr-1'>{documentoMask(usuario.documento)}</p>
                                </div>
                                <div className="text-sm font-medium text-gray-800 capitalize flex">
                                    <p className='break-words mr-1'>{usuario.razaoSocial}</p>
                                </div>
                                {usuario.chaveAC &&
                                    <div onClick={() => handleCopiarChaveAC(props.dadosLinha.usuario)} className="cursor-pointer flex text-xs font-medium text-green-600 capitalize flex truncate">
                                        <ClipboardIcon className='h-5 w-5 mr-1' /><p className='mt-0.5'>Chave AC</p>
                                    </div>
                                }
                            </div>
                        </div>
                        {ultimoAcesso ?
                            <div>
                                <div className={`text-sm font-medium text-gray-800 flex truncate ${sistema === "ERP" ? "text-emerald-700" : sistema === "PDV" ? "text-rose-900" : "text-gray-600"}`}>
                                    {sistema === "ERP" && <ChartBarIcon className='w-4 mr-1' />}
                                    {sistema === "PDV" && <ShoppingBagIcon className='w-4 mr-1' />}
                                    <p className='truncate mr-1'>{sistema || <i>Sistema</i>}</p>
                                </div>
                                <div className="flex text-sm text-gray-500 flex flex-row items-center gap-2 truncate">
                                    <p className='truncate mr-1'>Versão: {versaoSistema}</p>
                                    <span className={classNames(ultimaVersao ? "text-green-600 bg-green-200" : "text-red-600 bg-red-200", "font-bold h-fit w-fit text-white text-xs px-2 py-1 rounded-md")}>
                                        {ultimaVersao ? "Atualizado" : "Desatualizado"}
                                    </span>
                                </div>
                            </div>
                            :
                            <span className="text-red-600 bg-red-200 font-bold h-fit w-fit text-white text-xs px-2 py-1 rounded-md">
                                Nenhum Acesso
                            </span>
                        }
                        {ultimoAcesso && <div className="grid-cols-1 flex flex-col flex-1 lg:justify-self-end gap-2">
                            <div className={classNames(corTempo,
                                "text-sm font-medium flex capitalize px-2 h-fit w-fit rounded-lg")}>
                                <p className='break-words mr-1'>{ultimoAcesso}</p>
                            </div>
                            <div className="flex text-sm text-gray-500 flex">
                                <p className='break-words text-xs mr-1'>
                                    {new Date(dataUltimoAcesso).toLocaleDateString()},
                                    {new Date(dataUltimoAcesso).toLocaleTimeString()}
                                </p>
                            </div>
                        </div>}
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso(location.pathname.split("/")[1],
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaOpcoesCliente()
                carregaAnaliseDeUsos(registrosPorPagina, paginaAtual)
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaAnaliseDeUsos = async (pageSize = registrosPorPagina, currentPage = 0) => {
        const filtros = {
            pageSize,
            currentPage,
            clienteId: filtroCliente.value
        }

        const response = await getListAnaliseDeUso(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.currentPage)
            setTotalRegistros(response.data.totalRegisters)
            setTotalPaginas(response.data.totalPages)

            setListaAnaliseDeUso(response.data.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const filtroDebounce = useDebounce(carregaAnaliseDeUsos, 500)

    const renderLinhasTabela = () => {
        return listaAnaliseDeUso.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    function handleSelectCliente(clienteSelecionado) {
        setFiltroCliente(clienteSelecionado)
    }

    useEffect(() => {
        if (pesquisaCliente) clienteDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaCliente])

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <>
                {!listaAnaliseDeUso ?
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <EmptyPage texto="Nenhuma AnaliseDeUso Cadastrado" Icone={ClipboardIcon} />
                        </div>
                    </div>
                    :
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8'>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Cliente
                                </label>
                                <Select
                                    isDisabled={loading}
                                    name="cliente"
                                    value={filtroCliente}
                                    onChange={handleSelectCliente}
                                    onInputChange={value => setPesquisaCliente(value)}
                                    options={opcoesCliente}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma Cliente disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="bg-white sm:rounded-lg sm:shadow my-8">
                            <div className="bg-white sm:rounded-t-lg px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Análise De Uso
                                        </h3>
                                    </div>
                                    <div className="ml-4 mt-2 flex-shrink-0">

                                    </div>
                                </div>
                            </div>

                            <ul className="divide-y divide-gray-200">
                                {renderLinhasTabela()}
                            </ul>

                            <LayoutPaginacaoTabela
                                pagina={paginaAtual}
                                totalRegistros={totalRegistros}
                                registrosPorPagina={registrosPorPagina}
                                totalPaginas={totalPaginas}
                                className="rounded-b-lg"
                                onClickPaginaAnterior={() => {
                                    setPaginaAtual(paginaAtual - 1)
                                    carregaAnaliseDeUsos(registrosPorPagina, paginaAtual - 1)
                                }}
                                onClickPaginaPosterior={() => {
                                    setPaginaAtual(paginaAtual + 1)
                                    carregaAnaliseDeUsos(registrosPorPagina, paginaAtual + 1)
                                }}
                                onClickPagina={pagina => {
                                    setPaginaAtual(pagina)
                                    carregaAnaliseDeUsos(registrosPorPagina, pagina)
                                }} />
                        </div>
                    </div>
                }


                <ModalConfirmacao
                    alerta={false}
                    open={copiarChaveAC}
                    setOpen={setCopiarChaveAC}
                    titulo={`Chave AC de ${clienteSelecionado?.nomeCompleto}`}
                    textoBotao="Copiar chave"
                    acaoBotao={() => {
                        try {
                            navigator.clipboard.writeText(clienteSelecionado?.chaveAC)
                            toast.success(`Chave AC copiada!`, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } catch (error) {
                            toast.error('Erro ao copiar Chave AC', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }}
                >

                    <div className='h-80'>
                        <Scrollbars
                            autoHideDuration={200}
                            thumbMinSize={30}
                            universal={true}
                        >
                            <div className='bg-gray-100 rounded-lg p-4 inset-0 shadow-inner break-words overflow-hidden h-full'>
                                {clienteSelecionado?.chaveAC}
                            </div>
                        </Scrollbars>
                    </div>
                </ModalConfirmacao>
            </>
        )
    }

}