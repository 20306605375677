import React, { Fragment } from 'react';
import { Tab } from '@headlessui/react'
import MDEExecucao from './MDEExecucao';
import MDENotaFiscal from './MDENotaFiscal';
import MDEResumo from './MDEResumo';
import MDEEvento from './MDEEvento';
import MDECLiente from './MDECliente';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function MDE() {
    return (
        <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">

            <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                <div className="bg-white sm:rounded-t-lg px-4 py-5 border-b border-gray-200 sm:px-6 mt-8">
                    <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                        <div className="ml-4 mt-2">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Manifesto Destinatário Eletrônico
                            </h3>
                        </div>
                    </div>
                </div>

                <Tab.Group>
                    <Tab.List className="shadow rounded-b-lg overflow-hidden grid sm:grid-cols-5 grid-cols-1 gap-0 bg-white divide-x divide-y divide-gray-200">
                        <Tab as={Fragment}>
                            {({ selected }) => (
                                <button className={classNames(selected ? 'bg-blue-600/20 text-blue-600' : 'bg-white text-black', 'font-semibold p-2 flex-1')}>
                                    Clientes
                                </button>
                            )}
                        </Tab>
                        <Tab as={Fragment}>
                            {({ selected }) => (
                                <button className={classNames(selected ? 'bg-blue-600/20 text-blue-600' : 'bg-white text-black', 'font-semibold p-2 flex-1')}>
                                    Execuções
                                </button>
                            )}
                        </Tab>
                        <Tab as={Fragment}>
                            {({ selected }) => (
                                <button className={classNames(selected ? 'bg-blue-600/20 text-blue-600' : 'bg-white text-black', 'font-semibold p-2 flex-1')}>
                                    Resumos
                                </button>
                            )}
                        </Tab>
                        <Tab as={Fragment}>
                            {({ selected }) => (
                                <button className={classNames(selected ? 'bg-blue-600/20 text-blue-600' : 'bg-white text-black', 'font-semibold p-2 flex-1')}>
                                    Eventos
                                </button>
                            )}
                        </Tab>
                        <Tab as={Fragment}>
                            {({ selected }) => (
                                <button className={classNames(selected ? 'bg-blue-600/20 text-blue-600' : 'bg-white text-black', 'font-semibold p-2 flex-1')}>
                                    Notas Fiscais
                                </button>
                            )}
                        </Tab>
                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel><MDECLiente filtros={true} quantidadeResultados={10} /></Tab.Panel>
                        <Tab.Panel><MDEExecucao filtros={true} quantidadeResultados={10} /></Tab.Panel>
                        <Tab.Panel><MDEResumo filtros={true} quantidadeResultados={10} /></Tab.Panel>
                        <Tab.Panel><MDEEvento filtros={true} quantidadeResultados={10} /></Tab.Panel>
                        <Tab.Panel><MDENotaFiscal filtros={true} quantidadeResultados={10} /></Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </div>
    );
}

export default MDE;